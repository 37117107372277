import type {
  JsExceptionRecordDto,
  JsHttpRequestRecordDto,
  PublishingResponseDto,
} from '@zg-rentals/ts-dragnet-client';
import type { DragnetJsExceptionCategory } from '@zg-rentals/ts-dragnet-client';
import type { BatchWriter } from '@zg-rentals/util';
import { MaxLengthThrottledBatchWriter } from '@zg-rentals/util';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { redactUserInfo } from './redact';
import { getGlobalMonitor } from '@zg-rentals/monitor-base';
import { getEnvironment } from '@zg-rentals/environment-utils';

export type DragnetError = {
  error: Error;
  httpRequest?: JsHttpRequestRecordDto;
  category: DragnetJsExceptionCategory;
};

// 12 kB
const MAX_BODY_SIZE = 12288;
export const MAX_NAME_LENGTH = 200;

type requestFn = (batch: Array<JsExceptionRecordDto>, options: { appName: string }) => Promise<PublishingResponseDto>;

export type DragnetBaseOptions = {
  appName: string;
  serverName: string;
  appVersion: string;
  request: requestFn;
  throttleTime?: number;
};

export function parseStacktrace(stack: string) {
  const stackRegex = /at .* \((.*):(\d+):(\d+)\)/;
  const match = stackRegex.exec(stack);
  if (match) {
    const fileName = match[1];
    const lineNumber = parseInt(match[2], 10);
    return {
      fileName,
      lineNumber,
    };
  }
  return { fileName: 'unknown', lineNumber: -1 };
}

const MAX_EXCEPTIONS_TO_SEND = 100;

export function parseMethodName(stack: string) {
  const functionNameRegex = /at (.*) \(/;
  const match = functionNameRegex.exec(stack);
  if (match) {
    return match[1];
  }
  return undefined;
}

export function getNameFromMessage(message: string) {
  let name = message.replace(/\W/g, ' ').replace(/\s+/g, ' ').trim();

  if (name.toLowerCase().includes('failed to decode param')) {
    name = 'Failed to decode param';
  }

  return name.substring(0, MAX_NAME_LENGTH);
}

export class Dragnet {
  private appName: string;
  private serverName: string;
  private appVersion: string;
  private request: requestFn;
  private throttleTime?: number;
  private readonly sendException: BatchWriter<JsExceptionRecordDto, PublishingResponseDto>;

  constructor(options: DragnetBaseOptions) {
    this.appName = options.appName;
    this.serverName = options.serverName;
    this.appVersion = options.appVersion;
    this.request = options.request;
    this.throttleTime = options.throttleTime ?? 5000;

    this.sendException = new MaxLengthThrottledBatchWriter(
      async (batch: Array<JsExceptionRecordDto>) => {
        return await this.request(batch, {
          appName: this.appName,
        });
      },
      this.throttleTime,
      MAX_EXCEPTIONS_TO_SEND,
      (reason) => {
        const logger = getGlobalLogger('dragnetNode');
        logger?.error(reason);
      },
    );
  }

  public reportError({ error, httpRequest, category }: DragnetError) {
    if (!error.stack) {
      return;
    }

    /* Sample:
      Error: Region is missing
      at default (/usr/src/app/node_modules/@smithy/config-resolver/dist-cjs/index.js:117:11)
      at /usr/src/app/node_modules/@smithy/node-config-provider/dist-cjs/index.js:90:104
      at /usr/src/app/node_modules/@smithy/property-provider/dist-cjs/index.js:97:33
    */

    const { fileName, lineNumber } = parseStacktrace(error.stack);

    const exceptionRecord: JsExceptionRecordDto = {
      created: Date.now(),
      exceptionLocation: fileName,
      name: getNameFromMessage(error.message),
      serverName: this.serverName,
      serviceName: this.appName,
      stackTrace: error.stack,
      type: error.name,
      appVersion: this.appVersion,
      callOrigin: fileName,
      lineNumber,
      methodName: parseMethodName(error.stack),
      environment: getEnvironment() ?? 'production',
      category,
      additionalAlertRecipients: [],
      httpRequest: httpRequest
        ? {
            ...httpRequest,
            body: httpRequest?.body ? redactUserInfo(httpRequest.body).substring(0, MAX_BODY_SIZE) : undefined,
          }
        : undefined,
    };

    const p = this.sendException.write(exceptionRecord);
    this.recordMetrics(exceptionRecord);
    return p;
  }

  private recordMetrics(exceptionRecord: JsExceptionRecordDto) {
    const monitor = getGlobalMonitor();
    monitor?.count({ name: 'Exception ' + exceptionRecord.category });
    monitor?.count({ name: 'Exception ' + `${exceptionRecord.category} ${this.appName}` });
    monitor?.count({ name: 'Exception ' + `name ${exceptionRecord.name}` });
    monitor?.count({ name: 'Exception ' + exceptionRecord.type });
  }
}
