import type { Client } from '@zg-rentals/http-client';
import { ThrottledBatchWriter } from '@zg-rentals/util';
import { getEnvironment, injectAppNameHeader, isDev, isTest } from '@zg-rentals/environment-utils';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { memoize } from '@zg-rentals/zdash';

import {
  type MarlinJsCountBatchRequestDto,
  type MarlinJsGaugeBatchRequestDto,
  type MarlinJsMeasurementBatchRequestDto,
  MarlinJsMetricsCollectionHandler_addCounts,
  MarlinJsMetricsCollectionHandler_addGauges,
  MarlinJsMetricsCollectionHandler_addMeasurements,
  MarlinJsRefererMetricsCollectionHandler_addCounts,
  MarlinJsRefererMetricsCollectionHandler_addGauges,
  MarlinJsRefererMetricsCollectionHandler_addMeasurements,
} from '@zg-rentals/ts-marlin-client';

export const Handlers = {
  referer: {
    addCounts: MarlinJsRefererMetricsCollectionHandler_addCounts,
    addGauges: MarlinJsRefererMetricsCollectionHandler_addGauges,
    addMeasurements: MarlinJsRefererMetricsCollectionHandler_addMeasurements,
  },
  direct: {
    addCounts: MarlinJsMetricsCollectionHandler_addCounts,
    addGauges: MarlinJsMetricsCollectionHandler_addGauges,
    addMeasurements: MarlinJsMetricsCollectionHandler_addMeasurements,
  },
};

function enrichPayload<P>(payload: P & { browserTime?: number; environment?: string }): P {
  if (typeof window !== 'undefined') {
    payload.browserTime = Date.now();
  }

  const environment = getEnvironment();
  if (environment) {
    payload.environment = environment;
  }

  return payload;
}

export class Marlin {
  private readonly counts: ThrottledBatchWriter<MarlinJsCountBatchRequestDto, void>;
  private readonly gauges: ThrottledBatchWriter<MarlinJsGaugeBatchRequestDto, void>;
  private readonly measures: ThrottledBatchWriter<MarlinJsMeasurementBatchRequestDto, void>;

  protected readonly client: () => Promise<Client>;

  constructor(
    appName: string,
    getClient: () => Client | Promise<Client>,
    handlers: (typeof Handlers)['direct'] | (typeof Handlers)['referer'],
    intervalMs: number,
    private readonly bucketMs = 5_000,
  ) {
    // `getClient` allows derivatives to produce a custom client, optionally async, for injecting
    // secrets, etc. `memoize` ensures the underlying construction happens just once and the result
    // can be `await`ed as many times as necessary
    this.client = memoize(async () => {
      const client = await Promise.resolve(getClient());

      return client.extend(
        ({
          // default prefixUrl to prod marlin
          prefixUrl = 'https://marlin.hotpads.com',

          // Closer perf to window.sendBeacon since we need custom headers
          // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
          keepalive = true,
        }) => ({
          prefixUrl,
          keepalive,

          headers: injectAppNameHeader({}, appName),

          hooks: {
            beforeRequest: [
              (request) => {
                if (isDev() || isTest()) {
                  if (isDev()) {
                    getGlobalLogger('marlin')?.trace(
                      {
                        url: request.url,
                        method: request.method,
                      },
                      'skipping request',
                    );
                  }
                  return new Response(JSON.stringify({}), {
                    status: 200,
                  });
                }
              },
            ],
          },
        }),
      );
    });

    const onError = (variant: string) => (reason: unknown) => {
      getGlobalLogger('marlin')?.error(reason, `${variant} flush error`);
    };

    this.counts = new ThrottledBatchWriter(
      async (batch: Array<MarlinJsCountBatchRequestDto>) => {
        const merged = batch.reduce<Record<string, Record<string, MarlinJsCountBatchRequestDto>>>((counts, count) => {
          const { serverName = '', serviceName = '' } = count;

          counts[serverName] ??= {};

          counts[serverName][serviceName] ??= {
            ...count,
            serverName,
            serviceName,
            countStats: {},
          };

          const bucket = counts[serverName][serviceName];

          Object.entries(count.countStats).forEach(([ms, countsForMs]) => {
            const numberMs = Number(ms);
            const clampedMs = numberMs - (numberMs % this.bucketMs);

            bucket.countStats[clampedMs] ??= {};

            Object.entries(countsForMs).forEach(([name, { sum }]) => {
              bucket.countStats[clampedMs][name] ??= { sum: 0 };

              bucket.countStats[clampedMs][name].sum += Math.round(sum);
            });
          });

          return counts;
        }, {});

        for (const [serverName, serverCounts] of Object.entries(merged)) {
          for (const [serviceName, serviceCounts] of Object.entries(serverCounts)) {
            await handlers.addCounts(
              enrichPayload({
                ...serviceCounts,
                serverName,
                serviceName,
              }),
              await this.client(),
            );
          }
        }
      },
      intervalMs,
      onError('counts'),
    );

    this.gauges = new ThrottledBatchWriter(
      async (batch: Array<MarlinJsGaugeBatchRequestDto>) => {
        const merged = batch.reduce<Record<string, Record<string, MarlinJsGaugeBatchRequestDto>>>((gauges, gauge) => {
          const { serverName = '', serviceName = '' } = gauge;

          gauges[serverName] ??= {};

          gauges[serverName][serviceName] ??= {
            ...gauge,
            serverName,
            serviceName,
            gauges: {},
          };

          const bucket = gauges[serverName][serviceName];

          Object.entries(gauge.gauges).forEach(([ms, gaugesForMs]) => {
            const numberMs = Number(ms);
            const clampedMs = numberMs - (numberMs % this.bucketMs);

            bucket.gauges[clampedMs] ??= {};

            Object.entries(gaugesForMs).forEach(([name, { sum, min, max, count }]) => {
              if (bucket.gauges[clampedMs][name]) {
                bucket.gauges[clampedMs][name].count += count;
                bucket.gauges[clampedMs][name].sum += Math.round(sum);
                bucket.gauges[clampedMs][name].min = Math.min(bucket.gauges[clampedMs][name].min, Math.round(min));
                bucket.gauges[clampedMs][name].max = Math.max(bucket.gauges[clampedMs][name].max, Math.round(max));
              } else {
                bucket.gauges[clampedMs][name] = {
                  count,
                  sum: Math.round(sum),
                  min: Math.round(min),
                  max: Math.round(max),
                };
              }
            });
          });

          return gauges;
        }, {});

        for (const [serverName, serverGauges] of Object.entries(merged)) {
          for (const [serviceName, serviceGauges] of Object.entries(serverGauges)) {
            await handlers.addGauges(
              enrichPayload({
                ...serviceGauges,
                serverName,
                serviceName,
              }),
              await this.client(),
            );
          }
        }
      },
      intervalMs,
      onError('gauges'),
    );

    this.measures = new ThrottledBatchWriter(
      async (batch: Array<MarlinJsMeasurementBatchRequestDto>) => {
        const merged = batch.reduce<Record<string, Record<string, MarlinJsMeasurementBatchRequestDto>>>(
          (measurements, measurement) => {
            const { serverName = '', serviceName = '' } = measurement;

            measurements[serverName] ??= {};

            measurements[serverName][serviceName] ??= {
              ...measurement,
              serverName,
              serviceName,
              measurements: {},
            };

            const bucket = measurements[serverName][serviceName];

            Object.entries(measurement.measurements).forEach(([name, measurementsForName]) => {
              bucket.measurements[name] ??= [];

              measurementsForName.forEach((measure) => {
                const existing = bucket.measurements[name].find((point) => point.timeMs === measure.timeMs);

                if (existing) {
                  existing.value += measure.value;
                } else {
                  bucket.measurements[name].push({ ...measure });
                }
              });
            });

            return measurements;
          },
          {},
        );

        for (const [serverName, serverMeasurements] of Object.entries(merged)) {
          for (const [serviceName, serviceMeasurements] of Object.entries(serverMeasurements)) {
            await handlers.addMeasurements(
              enrichPayload({
                ...serviceMeasurements,
                serverName,
                serviceName,
              }),
              await this.client(),
            );
          }
        }
      },
      intervalMs,
      onError('measures'),
    );
  }

  public async sendCounts(counts: MarlinJsCountBatchRequestDto) {
    const isInvalid = Object.values(counts.countStats).some((countsForMs) =>
      Object.values(countsForMs).some((count) => !Number.isFinite(count.sum)),
    );

    if (isInvalid) {
      return getGlobalLogger('marlin')?.warn(`Invalid counts: ${JSON.stringify(counts)}`);
    }

    return this.counts.write(counts);
  }

  public async sendGauges(gauges: MarlinJsGaugeBatchRequestDto) {
    const isInvalid = Object.values(gauges.gauges).some((gaugesForMs) =>
      Object.values(gaugesForMs).some((gauge) => Object.values(gauge).some((num) => !Number.isFinite(num))),
    );

    if (isInvalid) {
      return getGlobalLogger('marlin')?.warn(`Invalid gauges: ${JSON.stringify(gauges)}`);
    }

    return this.gauges.write(gauges);
  }

  public async sendMeasure(measures: MarlinJsMeasurementBatchRequestDto) {
    const isInvalid = Object.values(measures.measurements).some((measurementsForMs) =>
      Object.values(measurementsForMs).some((measurement) =>
        Object.values(measurement).some((num) => !Number.isFinite(num)),
      ),
    );

    if (isInvalid) {
      return getGlobalLogger('marlin')?.warn(`Invalid measures: ${JSON.stringify(measures)}`);
    }

    return this.measures.write(measures);
  }

  public async sendMeasurementWithGauges(measures: MarlinJsMeasurementBatchRequestDto) {
    const gaugePromise = this.sendGauges({
      ...measures,
      gauges: Object.entries(measures.measurements).reduce<MarlinJsGaugeBatchRequestDto['gauges']>(
        (acc, [metricName, currMeasures]) => {
          currMeasures.forEach(({ timeMs, value: amount }) => {
            acc[timeMs] ||= {};
            if (acc[timeMs][metricName]) {
              acc[timeMs][metricName].sum += amount;
              acc[timeMs][metricName].count += 1;
              acc[timeMs][metricName].min = Math.min(acc[timeMs][metricName].min, amount);
              acc[timeMs][metricName].max = Math.max(acc[timeMs][metricName].max, amount);
            } else {
              acc[timeMs][metricName] = {
                sum: amount,
                count: 1,
                min: amount,
                max: amount,
              };
            }
          });
          return acc;
        },
        {},
      ),
    });

    return Promise.all([gaugePromise, this.sendMeasure(measures)]);
  }
}
