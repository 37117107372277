import DEPRECATED_Filter from 'app/shared/models/DEPRECATED_Filter';
import constants from 'app/shared/constants/ConstantsBundle';
import assign from 'lodash/assign';
import reduxUtils from 'app/shared/utils/reduxUtils';
import type { DeprecatedFilterState } from 'app/types/deprecatedFilter.type';

/**
 * filter must be created using FilterActions.createFilter()
 * BEFORE it gets to the reducers
 */

const mapActionsToReducer = {
  [constants.UPDATE_LES_FILTER_HARD]: (state: DeprecatedFilterState, action: { filterObj: DeprecatedFilterState }) => {
    // this will always return a new filter based on action. doesn't merge.
    return assign({}, action.filterObj);
  },
  [constants.UPDATE_LES_FILTER]: (state: DeprecatedFilterState, action: { filterObj: DeprecatedFilterState }) => {
    const filterObj = action.filterObj;

    return assign({}, state, filterObj);
  },
  [constants.UPDATE_FILTER]: (state: DeprecatedFilterState, action: { filterObj: DeprecatedFilterState }) => {
    return Object.assign({}, action.filterObj);
  },
};

const DEPRECATED_filter = reduxUtils.createReducer(mapActionsToReducer, DEPRECATED_Filter.create());

export default DEPRECATED_filter;
