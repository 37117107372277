import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';
import { ERROR_BOUNDARY_PREFIX, logError } from '@zg-rentals/log-error';
import { getGlobalMonitor } from '@zg-rentals/monitor-browser';

export const DEFAULT_ERROR_MESSAGE = 'Oops! Something went wrong. Please try again later.';

type Props = {
  children: ReactNode;
  fallback?: ReactNode;
  onError?: (error: Error) => void;
};

type State = {
  error: Error | null;
  hasError: boolean;
};

export class RpErrorBoundary extends Component<Props, State> {
  // method so teams that already have a custom EB can use to log errors the way RpErrorBoundary does
  static logError(error: Error, errorInfo: ErrorInfo, additionalContext?: Record<string, unknown>) {
    const monitor = getGlobalMonitor();
    monitor.count({ name: 'browser errorboundary total' });

    // we want to differentiate error boundary errors but also have the original error type (name)
    const errorType = `${ERROR_BOUNDARY_PREFIX}${error.name ? `_${error.name}` : '_UnknownError'}`;
    logError({
      error,
      errorType: errorType,
      context: {
        errorInfo,
        ...additionalContext,
      },
    });
  }

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    RpErrorBoundary.logError(error, errorInfo);
    this.props.onError?.(error);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback ?? <div role="alert">{DEFAULT_ERROR_MESSAGE}</div>;
    }

    return this.props.children;
  }
}
