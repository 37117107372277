interface ZillowApplicationsPageParams {
  campaign?: string | null;
  medium?: string | null;
}

interface ZillowApplicationsFlowParams {
  medium?: string | null;
  appId?: string | null;
}

const pathsManifest = {
  FrontPage: '/',

  SitemapPage: '/directory-sitemap',
  SitemapMfPage: '/building-directory-sitemap',

  UserDashboardPage: '/user/account',
  UserEditPage: '/user/edit',
  UserRenterProfilePage: '/user/renter-profile',
  UserSettingsPage: '/user/settings',
  MyHubPage: '/user/listings/my-hub',
  MyListPage: '/user/listings/my-list',
  ViewedItemsPage: '/user/listings/viewed',
  HiddenItemsPage: '/user/listings/hidden',

  SavedSearchPage: '/user/saved-search',
  MobileAppsPage: '/mobile-apps',
  AppleLoginRedirectPage: '/apple-auth',
  LoginPage: '/login',
  LogoutPage: '/logout',
  AboutUsPage: '/about-us',
  UnsubscribePage: '/unsubscribe',
  UnsubscribeSearchesPage: '/unsubscribe/searches',
  UnsubscribePropertyUpdatePage: '/unsubscribe/property-update',
  ReportListingAsUnavailablePage: '/report/listing-unavailable',
  EditSearchFrequencyPage: '/edit-search-frequency',
  ReactivateSearchesPage: '/reactivate-searches',
  MobileCreatePasswordPage: '/user/create-password/mobile',
  CreatePasswordPage: '/user/create-password',
  UpdatePasswordPage: '/user/update-password',
  ForgotPasswordPage: '/forgot-password',
  MobileChoicePage: '/mobile-choice',
  PostAListingPage: '/list-your-rental',

  // Near me pages
  ApartmentsNearMePage: '/apartments-near-me',
  HousesNearMe: '/houses-near-me',

  FeedbackSuccessPage: '/inquiry-feedback/success',
  PropertyReviewGuidelinesPage: '/property-review-guidelines',
  NetPromoterScorePage: '/net-promoter-score',
  ListYourRentalPage: '/list-your-rental',

  // REP URLS /node/rep/*
  AnalyticsPage: '/node/rep/ga-analytics',
  SsrTracePage: '/trace',

  // ADMIN URLS /hotpads-api/*
  ApiDashboardPage: 'https://hotpads-api.hotpads.com/hotpads-api/',
  TraceDetailsPage: 'https://hotpads-api.hotpads.com/hotpads-api/datarouter/traces/viewTrace?id=',
  ImpersonatePage: 'https://hotpads.com/hotpads-api/internal/user/changeUserId',

  // CCPA URL
  CcpaOptOutPage: '/optout',

  PaymentsPage: 'https://www.zillow.com/renter-hub/payments',
  InboxPage: '/renter-hub/inbox',

  ZillowApplicationsPage: ({ campaign = null, medium = null }: ZillowApplicationsPageParams): string =>
    `https://www.zillow.com/renter-hub/applications/?utm_medium=${medium}&utm_source=hotpads&utm_campaign=${
      campaign || 'applyNow'
    }`,

  ZillowApplicationsFlow: (
    providerListingId: string,
    { medium = null, appId = null }: ZillowApplicationsFlowParams,
  ): string =>
    `https://www.zillow.com/renter-hub/applications/listing/${providerListingId}/rental-application/?utm_medium=${medium}&utm_source=hotpads&utm_campaign=applyNow&rentalApplicationId=${appId}`,

  // EXTERNAL URLS
  zrmBase: 'https://www.zillow.com/rental-manager/',
  zrmPostAListingPage: 'https://www.zillow.com/rental-manager/properties?postingPath=true',
  zrmPostAListingLandingPage: 'https://www.zillow.com/rental-manager/post-a-listing/',
  zrmApplicationsPage: 'https://www.zillow.com/rental-manager/properties?mktPath=applications',
  zrmTenantScreeningPage: 'https://www.zillow.com/z/rental-manager/tenant-screening/',
  zrmCreateALeasePage: 'https://www.zillow.com/rental-manager/select-property?type=leases',
  zrmLeaseAgreementPage: 'https://www.zillow.com/z/rental-manager/rental-lease-agreements/',
  zrmPaymentsPage: 'https://www.zillow.com/rental-manager/select-property?type=payments',
  zrmCollectRentPage: 'https://www.zillow.com/z/rental-manager/collect-rent-online/',
  zrmPropertiesPage: 'https://www.zillow.com/rental-manager/properties',
  zillowRentalNetworkClaimThisPropertyPage:
    'https://www.zillow.com/rentals-network/rental-advertising/?itc=mfr_hp_hp_frbdp_btn_claimthisproperty',
  help: 'https://help.hotpads.com',
  blog: 'https://hotpads.com/blog',
  emergencyHelpPage:
    'https://help.hotpads.com/hc/en-us/articles/34008897590420-Essential-Tips-for-Finding-a-Rental-During-a-State-of-Emergency',
  feedsPage: 'https://www.zillow.com/rentals-network/listing-feeds/',
  privacy: 'https://www.zillowgroup.com/zg-privacy-policy/',
  terms: 'https://www.zillow.com/corp/Terms.htm',
  jobs: 'http://www.zillow.com/jobs/',
  hud: 'https://www.hud.gov/program_offices/fair_housing_equal_opp',
  mfAdvertising: 'https://www.zillow.com/marketing/rental-property-advertising/',
  fairHousingGuide: 'https://www.zillow.com/rental-manager/resources/fair-housing-guide/ ',
  zgCcpaCookiePage: 'https://privacy.zillowgroup.com/cookie',
  tightshipSeoSitemapBucket: 'https://anchor-xml-sitemaps.s3.amazonaws.com/jeffy/full/',
  rentalTermsOfUse: 'https://www.zillow.com/renter-hub/terms/Rental-User-Terms',

  // CCPA URLS
  ccpaPortal: 'https://privacy.zillowgroup.com',
  ccpaPortalStaging: 'https://coreprivacy-stable.zg-int.net',
  ccpaPortalDevStaging: 'https://coreprivacy-dev.zg-int.net',
  zillowQa: 'https://www.qa.zillow.net',
  zillowTest: 'http://www.tes600.zillow.net/',
  ccpaTruliaStaging: 'https://stage.trulia.com',

  // ZRM URLS
  MyProperties: '/rental-manager/properties',
  IpCheckPage: '/ip',
};

export default pathsManifest;
