// @ts-nocheck
/* eslint-enable */
/* eslint-disable promise/always-return */
// App
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import api from 'app/shared/utils/api';
import constants from 'app/shared/constants/ConstantsBundle';
import processAreasResponse from 'app/shared/utils/processAreasResponse';
import { batch } from 'react-redux';

// Lodash
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import max from 'lodash/max';
import omit from 'lodash/omit';
import get from 'lodash/get';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';
import AreaBoundaryCache from 'app/shared/cache/areaBoundaryCache';
import DotMapCache from 'app/shared/cache/dotMapCache';
import NotificationActions from 'app/shared/flux/actions/NotificationActions';
import UserItemActions from 'app/shared/flux/actions/UserItemActions';

// Models
import Area from 'app/shared/models/Area';
import Filter from 'app/shared/models/Filter';
import HdpVisitedUserEvent from 'app/shared/models/HdpVisitedUserEvent';
import RelatedAreas from 'app/shared/models/RelatedAreas';

// Misc / Utils
import adapterUtils from 'app/shared/utils/adapterUtils';
import gmapUtils from 'app/client/utils/map/gmapUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import searchSlugUtils from 'app/shared/utils/searchSlugUtils';
import { adapt_reduxToJava } from 'app/shared/flux/actions/FilterActions/adapters';
import { listingUtils_apiLimitedBuildingArrayToSummaryArray } from 'app/shared/utils/listingUtils';
import { getCurrentListingIndex } from 'app/shared/utils/listingsReducerUtils';
import { TrackReportHomeFromHdp } from 'app/shared/models/Clickstream/HdpClickstreamEvents';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('actions/listingengine');

const checkApiResult = function checkApiResult(functionName, result) {
  if (!result) {
    logError({
      error: 'ListingEngineActions API ERROR: %s %O',
      errorType: 'ListingEngineActionsError',
      context: { functionName, result },
    });
    return false;
  }
  if (result.error) {
    logError({
      error: 'ListingEngineActions API ERROR: %s %O',
      errorType: 'ListingEngineActionsError',
      context: { functionName, result },
    });
    return false;
  }
  if (result.category === 'SERVER_ERROR') {
    logError({
      error: 'ListingEngineActions API ERROR: %s %s',
      errorType: 'ListingEngineActionsError',
      context: { functionName, result: result.message },
    });
    return false;
  }

  if (!result.data) {
    logError({
      error: 'ListingEngineActions API ERROR: %s %s',
      errorType: 'ListingEngineActionsError',
      context: { functionName, result: result.message },
    });
    return false;
  }

  return true;
};

const ListingEngineActions = {
  setCurrentListing(currentListing, list = []) {
    return function (dispatch) {
      dispatch({
        type: constants.SET_CURRENT_LISTING,
        currentListing,
      });

      // listings that are part of buildings aren't
      // individual entries in byCoords
      if (!isEmpty(list) && !currentListing.building) {
        // find position of current listing within list
        const indexInList = getCurrentListingIndex(list, currentListing);

        dispatch(ListingEngineActions.setCurrentListingIndex(indexInList));
      }

      return Promise.resolve(currentListing || {});
    };
  },
  fetchMfListingsByArea(params) {
    return function (dispatch) {
      const { filter, area, limit = 6 } = params;

      const apiParams = adapt_reduxToJava({
        filter,
        area,
        limit,
        channels: 'HotPadsMainPremium',
      });

      return dispatch(api.listing.fetchByCoords(apiParams)).then((result) => {
        if (checkApiResult('fetchListingsByCoords', result)) {
          return adapterUtils.apiLimitedBuildingArrayToSummaryArray(result.data.buildings, filter);
        } else {
          return [];
        }
      });
    };
  },
  fetchListings(hideMobileRightSidebar) {
    return function (dispatch, getState) {
      const currentLocation = getState().location.current;
      const device = getState().app.device;
      const gmapLoaded = getState().app.gmapLoaded;
      const filter = getState().filter;
      const isMapView = hideMobileRightSidebar === true;
      const isMobile = device.screenWidth === 'sm';
      const border = getState().location.current.query.border;
      const shouldUseAreaBoundary = border !== false;
      let mapData = gmapLoaded ? gmapUtils.getMapData(window.map) : null;

      if (!isMapView && isMobile && shouldUseAreaBoundary) {
        mapData = null;
      }

      const page = max([currentLocation.query.page, 0]);
      const offset = constants.MAX_LISTINGS_PER_PAGE * max([page - 1, 0]);
      return dispatch(
        ListingEngineActions.fetchListingsByCoords({
          mapData,
          filter,
          limit: constants.FETCH_LISTINGS_LIMIT,
          offset,
          shouldUseAreaBoundary,
        }),
      );
    };
  },
  resetDotMap() {
    return () => {
      // Blow out cache
      return DotMapCache.clear();
    };
  },
  fetchDotMap(stringifiedQuery) {
    return (dispatch, getState) => {
      let area;
      let areaData = assign({}, getState().area.area);
      const gmapLoaded = getState().app.gmapLoaded;
      const filter = getState().filter;
      const border = false; // Set to false here so we always show dots outside of border.
      const shouldUseAreaBoundary = border !== false;
      const mapData = gmapLoaded ? gmapUtils.getMapData(window.map) : null;

      if (isEmpty(areaData)) {
        areaData = cloneDeep(getState().area.area);
      }

      const areaGeoJson = AreaBoundaryCache.get(areaData.id);
      const defaultBoundingBox = areaGeoJson && areaGeoJson.defaultBoundingBox;

      if (mapData && mapData.zoom && mapData.zoom > 20) {
        dispatch(ListingEngineActions.resetDotMap());
        return Promise.resolve(true);
      }

      if (defaultBoundingBox) {
        delete areaData.id;
      }

      if (shouldUseAreaBoundary && mapData) {
        area = assign({}, mapData, { id: areaData.id });
      } else if (shouldUseAreaBoundary) {
        area = areaData;
      } else if (mapData) {
        area = mapData;
      } else if (areaData) {
        area = areaData;
      } else {
        return Promise.resolve(false);
      }

      const limit = 16;

      const store = getState();
      const apiParams = adapt_reduxToJava({
        filter: filter || store.filter,
        area,
        limit,
      });

      const dotMapApiParams = omit(apiParams);

      if (!routeUtils.hasValidMinMaxLatLon(area)) {
        logError({
          error: 'Invalid lat lon info in ListingEngineActions#fetchDotMap',
          errorType: 'ListingEngineActionsError',
          context: { area },
        });
        return Promise.resolve(false);
      }

      // Returns list of **all** buildings for a given bounding box (minMax Lat/Lon).
      // Data is sliced to a smaller limit for display purposes using dotManager.
      return dispatch(api.listing.fetchDotMap(dotMapApiParams)).then((result = {}) => {
        const { data } = result || {};

        // Add dots to cache
        DotMapCache.add(stringifiedQuery, data.buildings);
      });
    };
  },
  fetchListingsByCoords(additionalParams = {}) {
    return function (dispatch, getState) {
      const store = getState();
      const promises = [];
      const { offset, limit, channels, area: paramArea, filter, shouldUseAreaBoundary } = additionalParams;
      let { mapData } = additionalParams;
      let area;
      let areaData = paramArea;

      if (isEmpty(areaData)) {
        areaData = cloneDeep(store.area.area);
      }

      const areaGeoJson = AreaBoundaryCache.get(areaData.id);
      const defaultBoundingBox = areaGeoJson && areaGeoJson.defaultBoundingBox;

      /**
       * HPWEB-2550 / HPWEB-3790:
       * If area is default bounding box, (happens with small
       * neighborhoods with no actual geoJson data),
       * set mapData to false to prevent app from trying to use area.id,
       * which would return 0 listings for this type of area. Otherwise,
       * app still tries to use "mapData" bounding box for fetching listings,
       * which will show listings outside of current boundary.
       */
      if (defaultBoundingBox && shouldUseAreaBoundary) {
        mapData = false;
      }

      if (shouldUseAreaBoundary && mapData) {
        area = assign({}, mapData, { id: areaData.id });
      } else if (shouldUseAreaBoundary) {
        area = areaData;
      } else if (mapData) {
        area = mapData;
      } else if (areaData) {
        area = areaData;
      } else {
        console.error('fetchListingsByCoords to use neither area nor mapdata');
        return Promise.resolve(false);
      }

      dispatch(AppActions.setAppStoreBool('fetchListingsByCoordsComplete', false));

      const apiParams = adapt_reduxToJava({
        filter: filter || store.filter,
        area,
        offset,
        limit,
        channels,
      });

      if (!routeUtils.hasValidMinMaxLatLon(area)) {
        logError({
          error: 'Invalid lat lon info in ListingEngineActions#fetchListingsByCoords',
          errorType: 'ListingEngineActionsError',
          context: { area },
        });
        return Promise.resolve(false);
      }

      promises.push(
        dispatch(api.listing.fetchByCoords(apiParams)).then((result) => {
          if (checkApiResult('fetchListingsByCoords', result)) {
            const orderBy = store.filter.orderBy;
            const searchSlug = store.filter.search.slug;
            // Supports HPWEB-5355: Limit pagination limit of affordable and lux slugs
            const isAffordableOrLuxSlug = searchSlugUtils.isAffordableOrLuxSearchSlug(searchSlug);
            const shouldLimitListings =
              (orderBy === 'lowPrice' || orderBy === 'highPrice') && result.data.buildings.length <= 20;
            const totalListings = isAffordableOrLuxSlug ? Math.ceil(result.data.numUnits / 2) : result.data.numUnits;
            const pastPageOne = store.location.current.query.page > 1;
            const apiResultHasNoListings = result.data.buildings.length < 1;
            const query = store.location.current.query;

            if (pastPageOne && apiResultHasNoListings) {
              const nonPaginatedQuery = omit(query, ['page']);
              const unpaginatedPath = routeUtils.buildPathWithQuery(store.location.current.pathname, nonPaginatedQuery);

              throw {
                redirect: true,
                to: unpaginatedPath,
              };
            }
            batch(() => {
              dispatch({
                type: constants.FETCH_LISTINGS_SUCCESS,
                payload: {
                  listings: adapterUtils.apiLimitedBuildingArrayToSummaryArray(
                    result.data.buildings,
                    store.filter,
                    shouldLimitListings,
                  ),
                  listingGroup: 'byCoords',
                  totalListings,
                  totalBuildings: result.data.numBuildingsAvailable,
                },
              });

              dispatch(AppActions.setAppStoreBool('fetchListingsByCoordsComplete', true));
            });
          }

          return true;
        }),
      );

      // for area pages min/max price
      const priceHistogramParams = assign({}, apiParams, { numBuckets: 100 });

      promises.push(
        dispatch(api.listing.priceHistogram(priceHistogramParams)).then((res) => {
          if (res.data) {
            const { buckets } = res.data;
            if (buckets) {
              const minPriceIndex = buckets.findIndex((bucket = {}) => bucket.percent >= 5);
              const maxPriceIndex = buckets.findIndex((bucket = {}) => bucket.percent >= 95);
              const minPrice = buckets[minPriceIndex] && buckets[minPriceIndex].min;
              const maxPrice = buckets[maxPriceIndex] && buckets[maxPriceIndex].max;

              dispatch({
                type: constants.FETCH_AREA_MIN_MAX_PRICE,
                payload: {
                  areaMinPrice: minPrice,
                  areaMaxPrice: maxPrice,
                },
              });
            }
          }

          return true;
        }),
      );

      // for price histogram in price filter
      const priceHistogramParamsV2 = assign({}, apiParams, {
        numBuckets: (1 / constants.PRICE_FILTER_INCREMENT) * 5000,
        bucketSize: constants.PRICE_FILTER_INCREMENT,
        useCache: false,
        lowPrice: 0, // ignore price filters - histogram will gray-out prices out of range
        highPrice: null,
      });

      promises.push(
        dispatch(api.listing.priceHistogram(priceHistogramParamsV2)).then((res) => {
          if (res.data) {
            const { buckets } = res.data;
            let maxCount = -Infinity;
            let medianBucket = { percent: 0 };

            if (buckets) {
              const priceHistogramArray = map(buckets, (b, i) => {
                const { min, count, percent } = b;

                if (count && Math.abs(50 - Math.floor(percent)) <= Math.abs(50 - Math.floor(medianBucket.percent))) {
                  // floor percentage to pick 2nd of 3 results (33.3333 is closer to 50 than 66.6667)
                  medianBucket = b;
                }

                if (i < buckets.length - 1) {
                  maxCount = Math.max(count, maxCount);
                  return {
                    min,
                    count,
                  };
                } else {
                  return {
                    min,
                    count: 0,
                  };
                }
              });

              dispatch({
                type: constants.LOAD_AREA_PRICE_HISTOGRAM,
                payload: {
                  priceHistogram: {
                    data: priceHistogramArray,
                    maxCount,
                    median: medianBucket.min,
                  },
                },
              });
            }
          }

          return true;
        }),
      );

      return Promise.all(promises);
    };
  },
  // TODO: combine with function above
  fetchListingsByCoordsWithoutAction(additionalParams = {}) {
    return function (dispatch, getState) {
      const { offset, limit, channels, mapData, area: paramArea, filter, shouldUseAreaBoundary } = additionalParams;
      let area;
      let areaData = paramArea;

      if (isEmpty(areaData)) {
        areaData = cloneDeep(getState().area.area);
      }

      const areaGeoJson = AreaBoundaryCache.get(areaData.id);
      const defaultBoundingBox = areaGeoJson && areaGeoJson.defaultBoundingBox;

      // HPWEB-2550: If area is default bounding box (happens with small / specific neighborhoods),
      // strip out area id, otherwise no listings will be returned.
      if (defaultBoundingBox) {
        delete areaData.id;
      }

      if (shouldUseAreaBoundary && mapData) {
        area = assign({}, mapData, { id: areaData.id });
      } else if (shouldUseAreaBoundary) {
        area = areaData;
      } else if (mapData) {
        area = mapData;
      } else if (areaData) {
        area = areaData;
      } else {
        console.error('fetchListingsByCoordsWithoutAction to use neither area nor mapdata');
        return Promise.resolve(false);
      }

      const store = getState();

      const apiParams = adapt_reduxToJava({
        filter: filter || store.filter,
        area,
        offset,
        limit,
        channels,
      });

      if (!routeUtils.hasValidMinMaxLatLon(area)) {
        logError({
          error: 'Invalid lat lon info in ListingEngineActions#fetchListingsByCoordsWithoutAction',
          errorType: 'ListingEngineActionsError',
          context: { area },
        });
        return Promise.resolve(false);
      }

      return dispatch(api.listing.fetchByCoords(apiParams));
    };
  },
  fetchNearbyAreasListings(areaResourceId) {
    return function (dispatch, getState) {
      const limit = constants.LISTINGS_PER_NEARBY_AREA_TO_FETCH;
      const type = 'city'; // TODO: API needs to automagically infer area type. Not working at the moment.

      if (!areaResourceId) {
        return Promise.resolve();
      }

      return dispatch(api.area.nearby({ type, areaResourceId })).then((result = {}) => {
        const promises = [];
        const nearbyAreasWithListings = [];
        const store = getState();
        const { filter } = store;

        if (result.data) {
          const processedAreas = processAreasResponse(result.data.areas);
          const nearbyAreas = new RelatedAreas({ [type]: processedAreas });

          forEach(nearbyAreas[type], (nearbyArea = {}) => {
            const { listingCounts } = nearbyArea;

            if (listingCounts[filter.search.slug] > 0) {
              promises.push(
                dispatch(
                  ListingEngineActions.fetchListingsForNearbyArea({
                    limit,
                    area: nearbyArea,
                    filter,
                    shouldUseAreaBoundary: true,
                  }),
                ).then((fetchResult = {}) => {
                  nearbyArea.listings = fetchResult.listings;
                  nearbyArea.count = fetchResult.count;
                  nearbyAreasWithListings.push(nearbyArea);
                }),
              );
            }
          });

          return Promise.all(promises).then(() => {
            dispatch({
              type: constants.SET_NEARBY_LISTINGS,
              payload: {
                nearbyAreas: nearbyAreasWithListings,
              },
            });
            return true;
          });
        }
      });
    };
  },
  fetchListingsForNearbyArea(additionalParams = {}) {
    return function (dispatch, getState) {
      const { offset, limit, channels, mapData, area: paramArea, filter, shouldUseAreaBoundary } = additionalParams;
      let area;
      let areaData = paramArea;

      if (isEmpty(areaData)) {
        areaData = cloneDeep(getState().area.area);
      }

      const areaGeoJson = AreaBoundaryCache.get(areaData.id);
      const defaultBoundingBox = areaGeoJson && areaGeoJson.defaultBoundingBox;

      // HPWEB-2550: If area is default bounding box (happens with small / specific neighborhoods),
      // strip out area id, otherwise no listings will be returned.
      if (defaultBoundingBox) {
        delete areaData.id;
      }

      if (shouldUseAreaBoundary && mapData) {
        area = assign({}, mapData, { id: areaData.id });
      } else if (shouldUseAreaBoundary) {
        area = areaData;
      } else if (mapData) {
        area = mapData;
      } else if (areaData) {
        area = areaData;
      } else {
        console.error('fetchListingsForNearbyAreas to use neither area nor mapdata');
        return Promise.resolve(false);
      }

      const store = getState();
      const apiParams = adapt_reduxToJava({
        filter: filter || store.filter,
        area,
        offset,
        limit,
        channels,
      });

      if (!routeUtils.hasValidMinMaxLatLon(area)) {
        logError({
          error: 'Invalid lat lon info in ListingEngineActions#fetchListingsForNearbyArea',
          errorType: 'ListingEngineActionsError',
          context: { area },
        });
        return Promise.resolve(false);
      }

      return dispatch(api.listing.fetchByCoords(apiParams)).then((result) => {
        if (checkApiResult('fetchListingsByCoords', result)) {
          return {
            listings: adapterUtils.apiLimitedBuildingArrayToSummaryArray(result.data.buildings, getState().filter),
            count: result.data.numUnits,
          };
        }

        return true;
      });
    };
  },
  clearNearbyAreas() {
    return function (dispatch) {
      return dispatch({
        type: constants.SET_NEARBY_LISTINGS,
        payload: {},
      });
    };
  },
  fetchNumberOfListings({ filter, isMapView = true } = {}) {
    return (dispatch, getState) => {
      const device = getState().app.device;
      const gmapLoaded = getState().app.gmapLoaded;
      const isMobile = device.screenWidth === 'sm';
      const border = getState().location.current.query.border;
      const shouldUseAreaBoundary = border !== false;
      let mapData = gmapLoaded ? gmapUtils.getMapData(window.map) : null;

      if (!isMapView && isMobile) {
        mapData = null;
      }

      return dispatch(
        ListingEngineActions.fetchListingsByCoordsWithoutAction({
          mapData,
          filter,
          limit: 0, // we only need the number of listings, not the listing details
          shouldUseAreaBoundary,
        }),
      ).then((result) => {
        if (checkApiResult('fetchListingsByCoords', result)) {
          dispatch({
            type: constants.FETCH_NUMBER_OF_LISTINGS,
            payload: {
              totalListings: result.data.numUnits,
            },
          });
          return true;
        }
      });
    };
  },
  fetchNumDefaultFilterListings({ filter = null, isMapView = true } = {}) {
    return function (dispatch, getState) {
      const filt = filter ? filter : new Filter();
      const device = getState().app.device;
      const gmapLoaded = getState().app.gmapLoaded;
      const isMobile = device.screenWidth === 'sm';
      const border = getState().location.current.query.border;
      const shouldUseAreaBoundary = border !== false;
      let mapData = gmapLoaded ? gmapUtils.getMapData(window.map) : null;

      if (!isMapView && isMobile) {
        mapData = null;
      }

      return dispatch(
        ListingEngineActions.fetchListingsByCoordsWithoutAction({
          mapData,
          filter: filt,
          limit: 0, // we only need the number of listings, not the listing details
          shouldUseAreaBoundary,
        }),
      ).then((result) => {
        if (checkApiResult('fetchListingsByCoords', result)) {
          dispatch({
            type: constants.SET_NUM_DEFAULT_FILTER_LISTINGS,
            payload: {
              numDefaultFilterListings: result.data.numUnits,
            },
          });
          return true;
        }
      });
    };
  },
  fetchNumListingsForFilter({ filter }) {
    return function (dispatch, getState) {
      const gmapLoaded = getState().app.gmapLoaded;
      const border = getState().location.current.query.border;
      const shouldUseAreaBoundary = border !== false;
      const mapData = gmapLoaded ? gmapUtils.getMapData(window.map) : null;

      return dispatch(
        ListingEngineActions.fetchListingsByCoordsWithoutAction({
          mapData,
          filter,
          limit: 0, // we only need the number of listings, not the listing details
          shouldUseAreaBoundary,
        }),
      ).then((result) => {
        if (checkApiResult('fetchListingsByCoords', result)) {
          return result.data.numUnits;
        }
      });
    };
  },
  getCommuteRoute(locationObj = {}) {
    return function (dispatch) {
      const params = {
        originLat: locationObj.originLat,
        originLon: locationObj.originLon,
        lat0: locationObj.lat0,
        lon0: locationObj.lon0,
        commuteTimeMode: locationObj.commuteTimeMode,
      };

      return dispatch(api.user.userPoint.route(params)).then((res) => {
        if (res && res.data && res.data.encodedRoutes) {
          return res.data.encodedRoutes[0];
        }
      });
    };
  },
  getCommuteTime(locationParams = {}) {
    return function (dispatch) {
      const params = locationParams;

      return dispatch(api.user.userPoint.commuteTime(params)).then((res) => {
        if (res && res.data) {
          const { commuteTimes } = res.data;

          return commuteTimes;
        }
      });
    };
  },
  sendInquiry({ options, listing, rentalSubmitId }) {
    return function (dispatch, getState) {
      const state = getState();
      const ref = state.analytics.ref;

      if (!options || isEmpty(options)) {
        return {
          error: true,
          message: 'Not enough information provided.',
        };
      }

      const params = {
        alias: options.alias,
        baths: options.baths,
        beds: options.beds,
        email: options.email,
        phone: options.phone,
        name: options.name,
        text: options.text,
        emailUser: options.emailUser,
        modelId: options.modelId,
        requestToApply: options.requestToApply || false,
        rentalSubmitId: rentalSubmitId,
      };

      // Check if user provided a move in date and include it in our params.
      if (options.checkInDate) {
        params.checkInDate = options.checkInDate;
      }

      if (ref) {
        params.ref = ref;
      }

      return dispatch(api.listing.inquiry(params)).then((response = {}) => {
        if (!response.success) {
          logger?.warn('INQUIRY_ERROR - DID NOT SEND INQUIRY');

          return {
            error: true,
            message: 'Error sending inquiry.',
            status: response.status, // check for deactivated account
          };
        }

        if (response.cachedRequest) {
          return {
            error: true,
            message: 'Cached inquiry for later.',
          };
        }

        dispatch(UserItemActions.addUserItem('inquiry', listing));

        if (includes(state.analytics.ref, 'browserNotification')) {
          const bnQuery = state.analytics.ref.split('-');

          dispatch(
            NotificationActions.notifications.track({
              notificationId: bnQuery[1],
              type: 'converted',
            }),
          );
        }

        return response;
      });
    };
  },
  sendReport(params) {
    return function (dispatch) {
      return dispatch(api.listing.report(params)).then((res) => {
        dispatch(analyticsEvent(gaEvents.HDP_REPORTED, { newLaneEvent: TrackReportHomeFromHdp() }));
        return res;
      });
    };
  },
  share: {
    email(params) {
      return function (dispatch) {
        return dispatch(api.listing.share.email(params)).then((res) => {
          dispatch(analyticsEvent(gaEvents.HDP_EMAIL_FRIEND));
          return res;
        });
      };
    },
    sms(params) {
      return function (dispatch) {
        return dispatch(api.listing.share.sms(params)).then((res) => {
          dispatch(analyticsEvent(gaEvents.HDP_SMS_FRIEND));
          return res;
        });
      };
    },
  },
  clearPreviewAndCurrent() {
    return function (dispatch) {
      dispatch({
        type: constants.CLEAR_PREVIEW_LISTING,
      });
      dispatch({
        type: constants.CLEAR_CURRENT_LISTING,
      });
      dispatch({
        type: constants.SET_ACTIVE_MARKER_MALONE_LOT_ID,
        activeMarkerMaloneLotId: null,
      });
    };
  },
  clearCurrentListing() {
    return (dispatch) => {
      dispatch({
        type: constants.CLEAR_CURRENT_LISTING,
      });
    };
  },
  clearCache() {
    return function (dispatch) {
      dispatch({
        type: constants.RESET_MAP_LISTING_CACHE,
      });
    };
  },
  setActiveMarkerMaloneLotId(maloneLotIdEncoded) {
    return function (dispatch, getState) {
      const activeMarkerMaloneLotId = getState().currentListingDetails.activeMarkerMaloneLotId;

      if (activeMarkerMaloneLotId !== maloneLotIdEncoded) {
        dispatch({
          type: constants.SET_ACTIVE_MARKER_MALONE_LOT_ID,
          activeMarkerMaloneLotId: maloneLotIdEncoded,
        });
      }
    };
  },
  clearActiveMarkerAndPreviewListing() {
    return function (dispatch) {
      dispatch({
        type: constants.SET_ACTIVE_MARKER_MALONE_LOT_ID,
        activeMarkerMaloneLotId: null,
      });
      dispatch({
        type: constants.SET_PREVIEW_LISTING,
        previewListing: null,
      });
    };
  },
  setPreviewListing(listing = {}) {
    return function (dispatch) {
      const { aliasEncoded } = listing;

      dispatch(AppActions.sendEventToApi('previewed', aliasEncoded));
      dispatch({
        type: constants.SET_PREVIEW_LISTING,
        previewListing: listing,
      });
    };
  },
  setListingEngineStoreBool(name, bool) {
    return function (dispatch) {
      dispatch({
        type: constants.SET_LISTING_ENGINE_STORE_BOOL,
        payload: {
          name,
          bool,
        },
      });
    };
  },
  sendHdpStats(aliasEncoded, viewStartTime) {
    return function (dispatch) {
      dispatch(api.user.event.create(HdpVisitedUserEvent(aliasEncoded, Date.now() - viewStartTime)));
    };
  },
  loadListingSeoLinks(lotId, keyword) {
    return (dispatch) => {
      return dispatch(api.listing.seoFooterByMaloneLotId(lotId, keyword)).then((result) => {
        const { data, success } = result;
        if (!success) {
          logError({
            error: `Error in ListingEngineActions#loadListingSeoLinks for lotId ${lotId}`,
            errorType: 'ListingEngineActionsError',
          });
          return false;
        }

        return dispatch({
          type: constants.LOAD_SEO_FOOTER_LINKS,
          payload: {
            seoFooterLinks: data,
          },
        });
      });
    };
  },

  // this doesn't change the current listing
  // it is merely the list position of the current hdp
  setCurrentListingIndex(index) {
    return (dispatch) => {
      return dispatch({
        type: constants.SET_CURRENT_LISTING_INDEX,
        payload: index,
      });
    };
  },

  setCurrentListingOutsideOfList(bool, lastVisitedListingUri = '') {
    return (dispatch) => {
      if (lastVisitedListingUri) {
        dispatch({
          type: constants.SET_LAST_VISITED_LISTING_FROM_LIST,
          payload: lastVisitedListingUri,
        });
      } else {
        dispatch({
          type: constants.SET_LAST_VISITED_LISTING_FROM_LIST,
          payload: '',
        });
      }

      return dispatch({
        type: constants.SET_CURRENT_LISTING_OUTSIDE_OF_LIST,
        payload: bool,
      });
    };
  },
  // Used for mWeb SRPs
  /**
   *
   * @param {Object} additionalParams - Additional parameters that get used in the fetchByCoords API call
   * @param {Object} AbortController - The AbortSignal object that allows you to abort one or more DOM requests as and when desired
   */
  fetchListingsOnlyByCoords(additionalParams = {}, abortControllerSignal) {
    return (dispatch, getState) => {
      const state = getState();
      batch(() => {
        dispatch(AppActions.setAppStoreBool('fetchListingsByCoordsComplete', false));
        dispatch({ type: constants.RESET_MAP_LISTING_CACHE });
      });
      const {
        mapData = null,
        filter,
        limit = constants.FETCH_LISTINGS_LIMIT,
        page = 0,
        shouldUseAreaBoundary = true,
      } = additionalParams;
      const offset = constants.MAX_LISTINGS_PER_PAGE * Math.max(page - 1, 0);
      let area;

      // Rely on Map data & boundary info
      if (mapData && shouldUseAreaBoundary) {
        area = Object.assign({}, mapData, { id: get(state.area, 'area.id', '117776782') });
      } else if (mapData) {
        // If no boundary, rely on map data
        area = Object.assign({}, mapData);
      } else {
        // Else, rely on reudx area data
        area = Object.assign(
          {},
          {
            id: get(state.area, 'area.id', '117776782'),
            maxLat: get(state.area, 'area.maxLat', 40.917577),
            maxLon: get(state.area, 'area.maxLon', -73.700272),
            minLat: get(state.area, 'area.minLat', 40.477399),
            minLon: get(state.area, 'area.minLon', -74.25909),
            type: get(state.area, 'area.type', 'city'),
          },
        );
      }

      const apiParams = adapt_reduxToJava({
        filter,
        area,
        offset,
        limit,
      });

      return dispatch(api.listing.fetchByCoords(apiParams, abortControllerSignal))
        .then((res) => {
          if (res.data) {
            const searchSlug = state.filter.search.slug;
            const orderBy = state.filter.orderBy;

            // Supports HPWEB-5355: Limit pagination limit of affordable and lux slugs
            const isAffordableOrLuxSlug = searchSlugUtils.isAffordableOrLuxSearchSlug(searchSlug);
            const shouldLimitListings =
              (orderBy === 'lowPrice' || orderBy === 'highPrice') && res.data.buildings.length <= 20;
            const totalListings = isAffordableOrLuxSlug ? Math.ceil(res.data.numUnits / 2) : res.data.numUnits;

            batch(() => {
              dispatch({
                type: constants.FETCH_LISTINGS_SUCCESS,
                payload: {
                  listings: listingUtils_apiLimitedBuildingArrayToSummaryArray(
                    res.data.buildings,
                    state.filter,
                    shouldLimitListings,
                  ),
                  listingGroup: 'byCoords',
                  totalListings,
                  totalBuildings: res.data.numBuildingsAvailable,
                },
              });
              dispatch(AppActions.setAppStoreBool('fetchListingsByCoordsComplete', true));
            });
            return Promise.resolve({ success: true });
          }
        })
        .catch((err) => {
          logError({ error: err, errorType: 'ListingEngineActionsError', context: 'fetchListingsByCoords failed' });
          throw err;
        });
    };
  },
  // Used for mWeb SRPs
  fetchListingsByCoordsForFilterPills({ filter }) {
    return (dispatch, getState) => {
      const state = getState();
      const gmapLoaded = state.app.gmapLoaded;
      const border = state.location.current.query.border;
      const shouldUseAreaBoundary = border !== false;
      const mapData = gmapLoaded ? gmapUtils.getMapData(window.map) : null;
      let area;

      // Rely on Map data & boundary info
      if (mapData && shouldUseAreaBoundary) {
        area = Object.assign({}, mapData, { id: get(state.area, 'area.id', '117776782') });
      } else if (mapData) {
        // If no boundary, rely on map data
        area = Object.assign({}, mapData);
      } else {
        // Else, rely on reudx area data
        area = Object.assign(
          {},
          {
            id: get(state.area, 'area.id', '117776782'),
            maxLat: get(state.area, 'area.maxLat', 40.917577),
            maxLon: get(state.area, 'area.maxLon', -73.700272),
            minLat: get(state.area, 'area.minLat', 40.477399),
            minLon: get(state.area, 'area.minLon', -74.25909),
          },
        );
      }

      const apiParams = adapt_reduxToJava({
        filter,
        area,
        limit: 0,
        shouldUseAreaBoundary,
      });

      return dispatch(api.listing.fetchByCoords(apiParams))
        .then((res) => res.data.numUnits)
        .catch((err) => {
          logError({
            error: err,
            errorType: 'ListingEngineActionsError',
            context: 'fetchListingsByCoordsForFilterPills failed',
          });
          throw err;
        });
    };
  },
  // Used for mWeb SRPs
  fetchExcludedListingsByCoords() {
    return (dispatch, getState) => {
      const state = getState();
      const filter = new Filter();
      const border = state.location.current.query.border;
      const gmapLoaded = state.app.gmapLoaded;
      const shouldUseAreaBoundary = border !== false;
      const mapData = gmapLoaded ? gmapUtils.getMapData(window.map) : null;

      let area;

      // Rely on Map data & boundary info
      if (mapData && shouldUseAreaBoundary) {
        area = Object.assign({}, mapData, { id: get(state.area, 'area.id', '117776782') });
      } else if (mapData) {
        // If no boundary, rely on map data
        area = Object.assign({}, mapData);
      } else {
        // Else, rely on reudx area data
        area = Object.assign(
          {},
          {
            id: get(state.area, 'area.id', '117776782'),
            maxLat: get(state.area, 'area.maxLat', 40.917577),
            maxLon: get(state.area, 'area.maxLon', -73.700272),
            minLat: get(state.area, 'area.minLat', 40.477399),
            minLon: get(state.area, 'area.minLon', -74.25909),
          },
        );
      }

      const apiParams = adapt_reduxToJava({
        filter,
        area,
        limit: 0, // we only need the number of listings, not the listing details
        shouldUseAreaBoundary,
      });

      return dispatch(api.listing.fetchByCoords(apiParams))
        .then((res) => {
          dispatch({
            type: constants.SET_NUM_DEFAULT_FILTER_LISTINGS,
            payload: {
              numDefaultFilterListings: res.data.numUnits,
            },
          });

          return Promise.resolve({ success: true });
        })
        .catch((err) => {
          logError({
            error: err,
            errorType: 'ListingEngineActionsError',
            context: 'fetchListingsByCoordsForFilterPills failed',
          });
          throw err;
        });
    };
  },
  fetchListingsForNearbyAreaForMobileSRP(additionalParams = {}) {
    return (dispatch, getState) => {
      const { offset, limit, channels, mapData, area: paramArea, filter, shouldUseAreaBoundary } = additionalParams;

      let area;
      let areaData = paramArea;

      if (isEmpty(areaData)) {
        areaData = Object.assign({}, getState().area.area);
      }

      const areaGeoJson = AreaBoundaryCache.get(areaData.id);
      const defaultBoundingBox = areaGeoJson && areaGeoJson.defaultBoundingBox;

      // HPWEB-2550: If area is default bounding box (happens with small / specific neighborhoods),
      // strip out area id, otherwise no listings will be returned.
      if (defaultBoundingBox) {
        delete areaData.id;
      }

      if (shouldUseAreaBoundary && mapData) {
        area = Object.assign({}, mapData, { id: areaData.id });
      } else if (shouldUseAreaBoundary) {
        area = areaData;
      } else if (mapData) {
        area = mapData;
      } else if (areaData) {
        area = areaData;
      } else {
        console.error('fetchListingsForNearbyAreas to use neither area nor mapdata');
        return Promise.resolve(false);
      }

      const apiParams = adapt_reduxToJava({
        filter,
        area,
        offset,
        limit,
        channels,
      });

      return dispatch(api.listing.fetchByCoords(apiParams)).then((res) => {
        if (res.data) {
          const listings = listingUtils_apiLimitedBuildingArrayToSummaryArray(res.data.buildings);
          const count = res.data.numUnits;
          return {
            listings,
            count,
          };
        }

        return false;
      });
    };
  },
  fetchNearbyAreasListingsForMobileSRP(areaResourceId, limit = constants.LISTINGS_PER_NEARBY_AREA_TO_FETCH) {
    return (dispatch, getState) => {
      const type = 'city';

      if (!areaResourceId) {
        return Promise.resolve({ success: false });
      }

      dispatch(AppActions.setAppStoreBool('fetchNearbyListingsComplete', false));

      return dispatch(api.area.nearby({ type, areaResourceId })).then((res = {}) => {
        const promises = [];
        const nearbyAreasWithListings = [];
        const store = getState();
        const { filter } = store;

        if (res.data) {
          const processedAreas = res.data.areas.map((area) => {
            return new Area(area);
          });
          const nearbyAreas = new RelatedAreas({ [type]: processedAreas });

          forEach(nearbyAreas[type], (nearbyArea = {}) => {
            const { listingCounts } = nearbyArea;

            if (listingCounts[filter.search.slug] > 0) {
              promises.push(
                dispatch(
                  ListingEngineActions.fetchListingsForNearbyAreaForMobileSRP({
                    limit,
                    area: nearbyArea,
                    filter,
                    shouldUseAreaBoundary: true,
                  }),
                ).then((fetchedRes = {}) => {
                  nearbyArea.listings = fetchedRes.listings;
                  nearbyArea.count = fetchedRes.count;
                  nearbyAreasWithListings.push(nearbyArea);
                }),
              );
            }
          });

          return Promise.all(promises).then(() => {
            dispatch({
              type: constants.SET_NEARBY_LISTINGS,
              payload: {
                nearbyAreas: nearbyAreasWithListings,
              },
            });
            dispatch(AppActions.setAppStoreBool('fetchNearbyListingsComplete', true));
            return Promise.resolve({ success: true });
          });
        }
      });
    };
  },
  hpTours: {
    clearInstantTourForm() {
      return (dispatch) => {
        dispatch({
          type: constants.CLEAR_INSTANT_TOUR_FORM,
        });
      };
    },
    updateInstantTourForm(updatedInstantTourFormFields) {
      return (dispatch) => {
        dispatch({
          type: constants.UPDATE_INSTANT_TOUR_FORM,
          payload: updatedInstantTourFormFields,
        });
      };
    },
    fetchAvailableToursForListing(listingAlias, tourType, scheduledDateWithOrWithoutTime = '') {
      return (dispatch) => {
        return dispatch(api.hpToursV3.latestAvailableTimes({ listingAlias, tourType })).then((res) => {
          if (res.success) {
            const { availableTimes, dateOnly, zoneId } = res.data;

            dispatch({
              type: constants.UPDATE_AVAILABLE_TOURS_FOR_LISTING,
              payload: {
                availableDatesWithOrWithoutTimes: availableTimes,
                dateOnly,
                scheduledDateWithOrWithoutTime,
                zoneId,
              },
            });
          }
        });
      };
    },
    clearAvailableToursForListing() {
      return (dispatch) => {
        dispatch({
          type: constants.UPDATE_AVAILABLE_TOURS_FOR_LISTING,
          payload: {},
        });
      };
    },
  },
};

export default ListingEngineActions;
