import assign from 'lodash/assign';
import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';
import liveExperiments from 'app/shared/experiments';
import { getRunningExperimentsInitialState } from 'app/shared/utils/experimentUtils';
import type { AnalyticsExperiment } from 'app/types/analyticsExperiment.type';

interface ExperimentPayloadFromAPI {
  featureName: string;
  treatment: string;
}

export interface AnalyticsState {
  runningExperiments: Record<string, AnalyticsExperiment>;
}

/**
AB TEST:
1) All you have to do is include the running experiment in AnalyticsStore.state.runningExperiments.
Note: api.abtest call will fetch the state for the runningExperiments on the server
2) Use `isControl` to render the content to be tested // {isControl ? <VersionA /> : <VersionB />}
3) Go to ab test dashboard (https://hotpads-api.hotpads.com/hotpads-api/internal/tool/ab/experimentDashboard)
and create experiment

IMPORTANT:
- experimentName **MUST** match object key for each experiment.
- uaCustomDimensionIndex must be an even number 40-58. Confirm with Oleg what it should be for your experiment.
- startDate is no longer in use. It is populated whenever you start the test in the ab test dashboard.

myCoolExperiment: {
    experimentName: 'myCoolExperiment',
    jira: 'HP-1234',
    enrolled: false,
    isControl: null,
    version: null,
    uaCustomDimensionIndex: 40
    allowNewUsersOnly: true // If not set explicitly, this defaults to `true` on the backend
}
**/

const initState = (): AnalyticsState => ({
  runningExperiments: getRunningExperimentsInitialState(liveExperiments) as Record<string, AnalyticsExperiment>,
});

const mapActionsToReducer = {
  [constants.LOAD_AB_TEST_EXPERIMENTS]: (
    state: AnalyticsState,
    action: {
      payload: {
        data: Array<ExperimentPayloadFromAPI>;
        experimentOverride: string;
        controlOverride: string;
      };
    },
  ) => {
    const newRunningExperiments = assign({}, state.runningExperiments);
    const { data, experimentOverride, controlOverride } = action.payload;
    if (data) {
      data.forEach((payloadExp: ExperimentPayloadFromAPI) => {
        const experimentName = payloadExp.featureName;
        const hasControlOverride = controlOverride === experimentName;
        const hasExperimentOverride = experimentOverride === experimentName;
        let isControl = payloadExp.treatment === 'off';
        if (hasControlOverride) {
          isControl = true;
        }
        if (hasExperimentOverride) {
          isControl = false;
        }
        newRunningExperiments[payloadExp.featureName] = assign({}, state.runningExperiments[payloadExp.featureName]);
        newRunningExperiments[payloadExp.featureName].treatment = payloadExp.treatment;
        newRunningExperiments[payloadExp.featureName].isControl = isControl;
        newRunningExperiments[experimentName].queryParamOverridePresent = hasControlOverride || hasExperimentOverride;
      });
    }

    return assign({}, state, {
      runningExperiments: newRunningExperiments,
    });
  },
};

const analytics = reduxUtils.createReducer(mapActionsToReducer, initState());

export default analytics;
