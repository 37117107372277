import assign from 'lodash/assign';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import union from 'lodash/union';
import without from 'lodash/without';

import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';
import type { FilterState } from 'app/types/filter.type';
import type { ListingDetails } from 'app/types/listingDetails.type';
import type { GeolocationArea } from 'app/shared/flux/reducers/geolocation';

interface Collection {
  filter: FilterState;
  collectionType: string;
  title: string;
  numOfNewResults: number;
  listings: Record<string, ListingDetails>;
  filterSummary: string;
  name: string;
  url: string;
}

export interface HomeHubState {
  lastViewedListing: ListingDetails | null;
  expandMySearchName: string;
  expandMySearch: Array<string>;
  googleAnalyticsPageView: string;
  userLocation: Record<string, unknown>;
  collections: Array<Collection>;
}

const initState = (): HomeHubState => ({
  lastViewedListing: null,
  expandMySearchName: '',
  expandMySearch: [],
  googleAnalyticsPageView: '',
  userLocation: {},
  collections: [],
});

const mapActionsToReducer = {
  [constants.HOMEHUB_COLLECTIONS_LOADED]: (
    state: HomeHubState,
    action: {
      payload: {
        collections: Array<unknown>;
        googleAnalyticsPageView: string;
      };
    },
  ): HomeHubState => {
    const { collections, googleAnalyticsPageView } = action.payload;
    return assign({}, state, {
      collections,
      googleAnalyticsPageView,
    });
  },
  [constants.HOMEHUB_CONTINUE_WITH]: (
    state: HomeHubState,
    action: {
      payload: {
        lastViewedListing: string | null;
      };
    },
  ): HomeHubState => {
    const lastViewedListing = action.payload;

    return assign({}, state, {
      googleAnalyticsPageView: `${state.googleAnalyticsPageView} | lastViewed`,
      lastViewedListing,
    });
  },
  [constants.HOMEHUB_EXPAND_SEARCH]: (
    state: HomeHubState,
    action: {
      payload: {
        areas: Array<unknown>;
        areaName: string;
      };
    },
  ): HomeHubState => {
    const { areas, areaName } = action.payload;
    const expandMySearch = map(areas, (area: { resourceId: string; name: string }) => {
      const { resourceId, name } = area;
      return {
        resourceId,
        name,
      };
    });

    return assign({}, state, {
      googleAnalyticsPageView: `${state.googleAnalyticsPageView} | expandMySearch`,
      expandMySearch,
      expandMySearchName: areaName,
    });
  },

  // This is loaded by AppTemplate
  // We may use this here as a fallback for users without search history
  [constants.GEOLOCATION_USER_AREA]: (
    state: HomeHubState,
    action: { type: string; area?: GeolocationArea },
  ): HomeHubState => {
    const area = action?.area || {};

    if (isEmpty(area)) {
      return state;
    }

    return assign({}, state, { userLocation: area });
  },

  // FIXME: Since we don't have a master collection of listings, any time user
  //        changes the state of a listing (e.g. fav/unfav) every listing everywhere has to update
  //        TODO: Maintain a single list, and we won't have to do this.
  [constants.USER_ITEM_OPTIMISTIC_TOGGLE]: (
    state: HomeHubState,
    action: {
      payload: {
        listing: Record<string, unknown>;
        type: string;
        action: string;
      };
    },
  ): HomeHubState => {
    const { listing, type, action: payloadAction } = action.payload;
    const { collections, lastViewedListing } = state;
    const { maloneLotIdEncoded } = listing as { maloneLotIdEncoded: string };
    let newCollections;
    let newLastViewed;

    if (type !== 'favorite') {
      return state;
    }

    const collectionWithListing = collections.filter((collection: { listings: Record<string, unknown> }) => {
      const { listings } = collection;
      return listings[maloneLotIdEncoded];
    });
    const isInCollection = collectionWithListing.length > 0;

    const isLastViewed = lastViewedListing ? maloneLotIdEncoded === lastViewedListing.maloneLotIdEncoded : false;

    if (!isInCollection && !isLastViewed) {
      return state;
    }

    if (isInCollection) {
      newCollections = collections.map((collection) => {
        const { listings } = collection;
        if (listings[maloneLotIdEncoded]) {
          const newCollection = cloneDeep(collection);
          const { listings: newListings } = newCollection;
          const newListing = newListings[maloneLotIdEncoded];
          if (payloadAction === 'add') {
            newListing.userItemTypes = union(newListing.userItemTypes, ['favorite']);
          }
          if (payloadAction === 'remove') {
            newListing.userItemTypes = without(newListing.userItemTypes, 'favorite');
          }
          return newCollection;
        } else {
          return collection;
        }
      });
    }

    if (isLastViewed) {
      newLastViewed = clone(lastViewedListing);
      if (newLastViewed && payloadAction === 'add') {
        newLastViewed.userItemTypes = union(newLastViewed.userItemTypes, ['favorite']);
      }
      if (newLastViewed && payloadAction === 'remove') {
        newLastViewed.userItemTypes = without(newLastViewed.userItemTypes, 'favorite');
      }
    }

    return assign({}, state, {
      collections: isInCollection ? newCollections : collections,
      lastViewedListing: isLastViewed ? newLastViewed : lastViewedListing,
    });
  },
  [constants.HOMEHUB_RESET]: (state: HomeHubState): HomeHubState => {
    return assign({}, state, {
      lastViewedListing: initState().lastViewedListing,
      expandMySearchName: initState().expandMySearchName,
      expandMySearch: initState().expandMySearch,
      googleAnalyticsPageView: initState().googleAnalyticsPageView,
      collections: initState().collections,
    });
  },
};

const reducer = reduxUtils.createReducer(mapActionsToReducer, initState());

export default reducer;
