import type { ClickstreamEvent } from 'app/types';
import { setPropertyInfoBlock } from './contextual-blocks/PropertyInfoBlock';

// @ts-expect-error TODO: Need to properly type store
import { store } from 'app/shared/flux/store/configureStore';
import { Envelope } from './Envelope';

/**

Copy and paste to quickly build out event templates
Idea: Need to automate this somehow?
{
    envelope: {
        event_template_id: '',
        event_template_version_id: '',
        event_type_id: '',
        event_type_version_id: ''
    },
    clickstream_trigger: {
        trigger_location_nm: '',
        trigger_type_nm: '',
        trigger_object_nm: '',
        trigger_source_nm: ''
    },
    semantic: {
        semantic_event_nm: ''
    }
}

To find more information related to a specific event on data portal, use event_type_id:
//       https://dataportal.zillowgroup.net/event-registry/{event_type_id}
// e.g., https://dataportal.zillowgroup.net/event-registry/4785
*/

// Valid input for frequently used triggers
type BuildingPpcTriggerObject = 'home_details_component|property_details' | 'home_details_component|summary';
type ContactFormLeadTriggerObject =
  | 'home_details_component|contact_form'
  | 'home_details_component|inline_form'
  | 'home_details_component|modal_contact_form'
  | 'home_details|post_contact_modal';
type PhoneLeadTriggerObject = 'home_details_component|inline_form' | 'home_details_component|modal_contact_form';
type LeadTriggerSource =
  | 'button_to_complete_contact_form'
  | 'button_to_complete_request_to_apply_form'
  | 'button_to_send_message';

export type SaveHomeTriggerLocation =
  | 'user_item_list_page'
  | 'my_hub_page'
  | 'home_details'
  | 'hp_homepage'
  | 'user_item_viewed_page'
  | 'search_results'
  | 'user_item_hidden_page';
export type SaveHomeTriggerObject =
  | 'property_card'
  | 'my_hub_component|last_search'
  | 'home_details_component|recently_viewed'
  | 'home_details_component|more_from_provider'
  | 'home_details_component|post_contact_modal'
  | 'my_hub_component|contact'
  | 'my_hub_component|last_viewed'
  | 'my_hub_component|last_search'
  | 'home_details_component|action_bar'
  | 'my_hub_component|favorite';

export const TrackViewHdp = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '4785', eventVersion: '2', templateId: '285', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'home_details',
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackSaveHomeClick = ({
  listing,
  triggerLocation,
  triggerObject,
}: {
  listing: any;
  triggerLocation: SaveHomeTriggerLocation;
  triggerObject: SaveHomeTriggerObject;
}): ClickstreamEvent => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '4804', eventVersion: '2', templateId: '285', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: triggerLocation,
      trigger_source_nm: 'button_to_save_home',
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'save_home',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackUnSaveHomeClick = ({
  listing,
  triggerLocation,
  triggerObject,
}: {
  listing: any;
  triggerLocation: SaveHomeTriggerLocation;
  triggerObject: SaveHomeTriggerObject;
}): ClickstreamEvent => {
  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '5051', eventVersion: '2', templateId: '285', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: triggerLocation,
      trigger_source_nm: 'button_to_save_home',
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'unsave_home',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackHideHomeFromHdp = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '5052', eventVersion: '2', templateId: '285', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_hide_home',
      trigger_object_nm: 'home_details_component|action_bar',
    },
    semantic: {
      semantic_event_nm: 'hide_home',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackUnhideHomeFromHdp = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '5055', eventVersion: '2', templateId: '285', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_hide_home',
      trigger_object_nm: 'home_details_component|action_bar',
    },
    semantic: {
      semantic_event_nm: 'unhide_home',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

// Note (for Clickstream testing): No GA event associated with this.
// This should be attached to both Upper and Lower PPC events.
export const TrackBuildingPayPerLinkClick = ({
  triggerObject,
}: {
  triggerObject: BuildingPpcTriggerObject;
}): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '5056', eventVersion: '2', templateId: '285', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'link_to_open_pay_per_click',
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'building_pay_per_link',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackLowerPpcClick = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5566',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'link_to_open_pay_per_click',
      trigger_object_nm: 'home_details_component|property_details',
    },
    semantic: {
      semantic_event_nm: 'ppc_click_lower',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackUpperPpcClick = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5567',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'link_to_open_pay_per_click',
      trigger_object_nm: 'home_details_component|summary',
    },
    semantic: {
      semantic_event_nm: 'ppc_click_upper',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackInlineContactFormView = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5568',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'impression',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'hdp_contact_form',
      trigger_object_nm: 'home_details_component|inline_form',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

// Note (for Clickstream testing): No GA event associated with this.
export const TrackModalContactFormView = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5569',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'impression',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'modal_contact_form',
      trigger_object_nm: 'home_details_component|modal_contact_form',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackInlineContactSubmit = ({ rentalSubmitId }: { rentalSubmitId: string }): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5570',
      eventVersion: '2',
      templateId: '68',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_complete_contact_form',
      trigger_object_nm: 'home_details_component|inline_form',
    },
    semantic: {
      semantic_event_nm: 'inline_contact_submit',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
    csPayload.contact_request_form = { rental_submit_id: rentalSubmitId };
  }

  return csPayload;
};

export const TrackModalContactSubmit = ({
  rentalSubmitId,
  triggerSource,
}: {
  rentalSubmitId: string;
  triggerSource: LeadTriggerSource;
}): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5571',
      eventVersion: '2',
      templateId: '68',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: triggerSource,
      trigger_object_nm: 'home_details_component|modal_contact_form',
    },
    semantic: {
      semantic_event_nm: 'modal_contact_submit',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
    csPayload.contact_request_form = { rental_submit_id: rentalSubmitId };
  }

  return csPayload;
};

/**
 * User clicks send message CTA on similar listings to contact multiple listings.
 */
export const TrackSimilarListingMultiContactMessageButtonClick = () => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '7276',
      eventVersion: '2',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_send_message',
      trigger_object_nm: 'home_details|post_contact_modal',
    },
    semantic: {
      semantic_event_nm: 'send_message_similar_listing',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

/**
 * When user clicks Send Message on the similar listings post contact, this will track
 * individually how many listings were bulk contacted using that button.
 */
export const TrackSimilarListingMultiContactSubmit = ({
  rentalSubmitId,
  similarListing,
}: {
  rentalSubmitId: string;
  similarListing: {
    active: boolean;
    aliasEncoded: string;
    isPaidMultifamily: boolean;
    listingType: string;
    maloneLotIdEncoded: string;
    propertyType: string;
  };
}) => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '7283',
      eventVersion: '1',
      templateId: '68',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_send_message',
      trigger_object_nm: 'home_details|post_contact_modal',
    },
    semantic: {
      semantic_event_nm: 'similar_listing_contact_submit',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(similarListing);
    csPayload.contact_request_form = { rental_submit_id: rentalSubmitId };
  }

  return csPayload;
};

export const TrackApplyNowClick = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5572',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_open_apply_now_interstitial',
      trigger_object_nm: 'home_details_component|summary',
    },
    semantic: {
      semantic_event_nm: 'apply_now_click_hdp',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackRentalAppPreStart = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5573',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_open_apply_now_form',
      trigger_object_nm: 'home_details_component|application_interstitial',
    },
    semantic: {
      semantic_event_nm: 'rental_application_pre_start',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackRequestContactStart = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5574',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_open_contact_form',
      trigger_object_nm: 'home_details_component|summary',
    },
    semantic: {
      semantic_event_nm: 'request_contact_start',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackFreeListingLead = ({
  triggerObject,
  triggerSource,
}: {
  triggerObject: ContactFormLeadTriggerObject;
  triggerSource: LeadTriggerSource;
}): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5575',
      eventVersion: '5',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: triggerSource,
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'lead_email_free',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackPaidListingLead = ({
  triggerObject,
  triggerSource,
}: {
  triggerObject: ContactFormLeadTriggerObject;
  triggerSource: LeadTriggerSource;
}): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5576',
      eventVersion: '5',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: triggerSource,
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'lead_email_paid',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackDemotedListingLead = ({
  triggerObject,
  triggerSource,
}: {
  triggerObject: ContactFormLeadTriggerObject;
  triggerSource: LeadTriggerSource;
}): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5577',
      eventVersion: '5',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: triggerSource,
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'lead_email_paid_demoted',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackPhoneLeadForFreeProperty = ({
  triggerObject,
}: {
  triggerObject: PhoneLeadTriggerObject;
}): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5597',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'link_to_phone_number',
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'lead_phone_free',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackPhoneLeadForDemotedProperty = ({
  triggerObject,
}: {
  triggerObject: PhoneLeadTriggerObject;
}): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5599',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'link_to_phone_number',
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'lead_phone_paid_demoted',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackPhoneLeadForPaidProperty = ({
  triggerObject,
}: {
  triggerObject: PhoneLeadTriggerObject;
}): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5600',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'link_to_phone_number',
      trigger_object_nm: triggerObject,
    },
    semantic: {
      semantic_event_nm: 'lead_phone_paid',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackReportHomeFromHdp = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '5715', eventVersion: '1', templateId: '4', templateVersion: '1' }),

    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_report_home',
      trigger_object_nm: 'home_details_component|action_bar',
    },
    semantic: {
      semantic_event_nm: 'report_home',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackHdpNextClick = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '5716', eventVersion: '1', templateId: '4', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_next_hdp',
      trigger_object_nm: 'home_details_component|action_bar',
    },
    semantic: {
      semantic_event_nm: 'next_hdp',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackHdpSimilarListingClick = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '5901', eventVersion: '1', templateId: '4', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'property_card',
      trigger_object_nm: 'home_details_component|similar_listings',
    },
    semantic: {
      semantic_event_nm: 'similar_listing_click',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackHdpPhotoGalleryOpen = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '5993', eventVersion: '1', templateId: '4', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'link_to_open_gallery',
      trigger_object_nm: 'home_details_component|photos',
    },
    semantic: {
      semantic_event_nm: 'open_photo_player',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackHdpPrevClick = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '5717', eventVersion: '1', templateId: '4', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_prev_hdp',
      trigger_object_nm: 'home_details_component|action_bar',
    },
    semantic: {
      semantic_event_nm: 'prev_hdp',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackShareHomeFromHdp = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '5718', eventVersion: '1', templateId: '4', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'button_to_share_home',
      trigger_object_nm: 'home_details_component|action_bar',
    },
    semantic: {
      semantic_event_nm: 'share_home',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackHdpNativeShare = () => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5925',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'native_share_tray',
      trigger_object_nm: 'home_details_component|share_options',
    },
    semantic: {
      semantic_event_nm: 'native_share',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackHdpCopyShareUrl = () => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5926',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'link_to_copy_share_url',
      trigger_object_nm: 'home_details_component|share_options',
    },
    semantic: {
      semantic_event_nm: 'copy_url_share',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackHdpTextShare = () => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5927',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'link_to_text_share_url',
      trigger_object_nm: 'home_details_component|share_options',
    },
    semantic: {
      semantic_event_nm: 'text_share',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackHdpEmailShare = () => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({
      eventId: '5928',
      eventVersion: '1',
      templateId: '4',
      templateVersion: '1',
    }),
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'link_to_email_share_url',
      trigger_object_nm: 'home_details_component|share_options',
    },
    semantic: {
      semantic_event_nm: 'email_share',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};
