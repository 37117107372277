import { combineReducers } from 'redux';
import type { AnalyticsState } from './analytics';
import analytics from './analytics';
import type { AppState } from './app';
import app from './app';
import type { AreaState } from './area';
import area from './area';
import type { CurrentListingDetailsState } from './currentListingDetails';
import currentListingDetails from './currentListingDetails';
import DEPRECATED_filter from './DEPRECATED_filter';
import filter from './filter';
import type { ListingsState } from './listings';
import listings from './listings';
import type { UserState } from './user';
import user from './user';
import type { LocationState } from './location';
import location from './location';
import type { AdsState } from './ads';
import ads from './ads';
import type { FetchListingState } from './fetchListing';
import fetchListing from './fetchListing';
import type { GeolocationState } from './geolocation';
import geolocation from './geolocation';
import type { ViewState } from './view';
import view from './view';
import type { SEOState } from './seo';
import seo from './seo';
import type { HomeHubState } from './homehub';
import homehub from './homehub';
import type { DeprecatedFilterState } from 'app/types/deprecatedFilter.type';
import type { FilterState } from 'app/types/filter.type';

export interface RootReduxState {
  ads: AdsState;
  analytics: AnalyticsState;
  app: AppState;
  area: AreaState;
  currentListingDetails: CurrentListingDetailsState;
  fetchListing: FetchListingState;
  DEPRECATED_filter: DeprecatedFilterState;
  filter: FilterState;
  geolocation: GeolocationState;
  listings: ListingsState;
  location: LocationState;
  user: UserState;
  view: ViewState;
  seo: SEOState;
  homehub: HomeHubState;
}

const rootReducer = () =>
  combineReducers<RootReduxState>({
    ads,
    analytics,
    app,
    area,
    currentListingDetails,
    fetchListing,
    DEPRECATED_filter,
    filter,
    geolocation,
    listings,
    location,
    user,
    view,
    seo,
    homehub,
  });

export default rootReducer;
