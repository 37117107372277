import { makeModuleInstance } from '@zg-rentals/util';

export const appInfo = makeModuleInstance<
  | {
      name: string;
      buildNumber: number;
    }
  | undefined
>(undefined);

export const gitInfo = makeModuleInstance<
  | {
      branch?: string;
      commit: string;
    }
  | undefined
>(undefined);
