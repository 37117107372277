import { createClient, getClient as getClientBase, setClient } from '@zg-rentals/http-client';

const defaultPrefixUrl =
  typeof __API__ !== 'undefined' // Webpack apps
    ? __API__
    : typeof process.env.NEXT_PUBLIC_API !== 'undefined' // Next.js apps
      ? process.env.NEXT_PUBLIC_API
      : undefined;

// Allow apps to override base http client if they desire, though take care to set defaults
// in a way that is roughly backwards compatible
export const getClient = () =>
  getClientBase().extend(
    ({ credentials = 'include', prefixUrl = defaultPrefixUrl, redirect = 'error', timeout = 60 * 1000 }) => ({
      credentials,
      prefixUrl,
      redirect,
      timeout,
    }),
  );

export { createClient, setClient };
