import Filter from 'app/shared/models/Filter';
import CONSTANTS from 'app/shared/constants/DispatchConstants';

interface Action {
  type: string;
  payload: object;
}

const initState = new Filter();
const filter = (state = initState, action: Action = {} as Action) => {
  const { type, payload } = action;

  if (type === CONSTANTS.UPDATE_MWEB_FILTER) {
    return {
      ...state,
      ...payload,
    };
  }

  return state;
};

export default filter;
