import getBrowserLogger from '@zg-rentals/logger-browser';
import type { Logger, LoggerOptions } from '@zg-rentals/logger-browser';
import type { MonitorPlugin, MonitorReporter } from '@zg-rentals/monitor-base';
import { Bootstrap, BootstrapBuilder } from '@zg-rentals/rp-bootstrap-base';
import { BrowserTracer } from '@zg-rentals/trace-browser';
import { MarlinBrowser } from '@zg-rentals/marlin-browser';
import { getCurrentEnvForClientSide, PRODUCTION } from '@zg-rentals/rental-platform-config';
import { isDev } from '@zg-rentals/environment-utils';
import { logError } from '@zg-rentals/log-error';

import {
  BrowserLogReporter,
  BrowserMonitor,
  BrowserMonitorMarlinReporter,
  DatadogPlugin,
  ExceptionReporter,
  WebVitalsPlugin,
} from '@zg-rentals/monitor-browser';

export class BootstrapBrowserBuilder extends BootstrapBuilder {
  private loggerOptions: LoggerOptions = {};
  private initializeLogger = (logger: Logger) => logger;

  withLoggerOptions(loggerOptions: LoggerOptions, initializeLogger?: (logger: Logger) => Logger) {
    this.loggerOptions = loggerOptions;
    if (initializeLogger) {
      this.initializeLogger = initializeLogger;
    }
    return this;
  }

  withHostedAppLoggerOptions(hostedApp: string, level = 'warn') {
    return this.withLoggerOptions({ level, logPath: `/${hostedApp}/hostedApp/log?appName=${this.appName}` });
  }

  private monitorOptions: {
    plugins: Array<(logger: Logger) => MonitorPlugin>;
    reporters: Array<(logger: Logger) => MonitorReporter>;
  } = {
    plugins: [(logger) => new WebVitalsPlugin(logger)],
    reporters: [
      (logger) =>
        new BrowserMonitorMarlinReporter(this.appName, new MarlinBrowser(this.appName), {
          logger: logger.child({ name: 'BrowserMonitorMarlinReporter' }),
        }),
    ],
  };

  withMonitorPlugin(plugin: (logger: Logger) => MonitorPlugin) {
    this.monitorOptions.plugins.push(plugin);
    return this;
  }

  withDatadogPlugin(applicationId: string, clientToken: string, service = this.appName) {
    if (typeof window !== 'undefined' && getCurrentEnvForClientSide() === PRODUCTION) {
      this.withMonitorPlugin(() => new DatadogPlugin(applicationId, clientToken, service, `${this.buildNumber}`));
    }

    return this;
  }

  withMonitorReporter(reporter: (logger: Logger) => MonitorReporter) {
    this.monitorOptions.reporters.push(reporter);
    return this;
  }

  private excludeExceptions = false;

  withExcludeExceptions(excludeExceptions: boolean) {
    this.excludeExceptions = excludeExceptions;
    return this;
  }

  build() {
    return new BootstrapBrowser(
      this.appName,
      this.buildNumber,
      this.excludeExceptions,
      { ...this.loggerOptions, initializeLogger: this.initializeLogger },
      this.monitorOptions,
    );
  }
}

export class BootstrapBrowser extends Bootstrap {
  constructor(
    appName: string,
    buildNumber: number,
    excludeExceptions: boolean,
    loggerOptions: LoggerOptions & { initializeLogger: (logger: Logger) => Logger },
    monitorOptions: {
      plugins?: Array<(logger: Logger) => MonitorPlugin>;
      reporters?: Array<(logger: Logger) => MonitorReporter>;
    },
  ) {
    const logger = loggerOptions.initializeLogger(
      getBrowserLogger({
        ...loggerOptions,
        name: appName,
        logPath: isDev() ? undefined : loggerOptions.logPath,
      }),
    );

    // Inject a log reporter if no other reporters are configured, nicer devexp
    const reporters = monitorOptions.reporters?.map((reporter) => reporter(logger)) ?? [];
    if (!reporters.length) {
      reporters.push(new BrowserLogReporter({ logger: logger.child({ name: 'browser-log-reporter' }) }));
    }

    if (!excludeExceptions) {
      reporters.push(
        new ExceptionReporter({
          appName: appName,
          logger: logger.child({ name: 'browser-exception-reporter' }),
          appVersion: String(buildNumber),
        }),
      );
    }

    const monitor = new BrowserMonitor({
      logger,
      plugins: monitorOptions.plugins?.map((plugin) => plugin(logger)),
      reporters,
    });

    const tracer = new BrowserTracer(monitor);

    super(appName, buildNumber, logger, monitor, tracer);

    this.onRegister(() => {
      monitor.count({ name: 'browser init' });
      monitor.count({ name: `buildNumber browser ${this.buildNumber}` });

      tracer.init();

      window.addEventListener('error', ({ error, filename, lineno, colno }) => {
        if (error != null) {
          logError({
            error,
            context: {
              filename,
              lineNumber: lineno,
              columnNumber: colno,
            },
          });
        }
      });

      window.addEventListener('unhandledrejection', ({ reason }) =>
        logError({ error: new Error(reason), errorType: 'unhandledRejection' }),
      );
    });
  }
}

export { Bootstrap };
