import type { AnalyticsExperiment } from 'app/types/analyticsExperiment.type';
import { getResolvedExperimentName } from '../utils/experimentUtils';

export class Experiment {
  experimentName: string;
  jira: string = '';
  isControl: boolean = true;
  allowNewUsersOnly: boolean = true;
  treatment: string = '';
  queryParamOverridePresent: boolean = false;

  constructor(opts: AnalyticsExperiment) {
    const { experimentName, jira = '', allowNewUsersOnly, isControl } = opts;

    if (!experimentName) {
      throw new Error('required argument "experimentName" is missing');
    }

    if (experimentName === getResolvedExperimentName({ experimentName, jira } as Experiment)) {
      throw new Error('experiment name cannot include jira ID');
    }

    this.experimentName = experimentName;
    this.jira = jira || this.jira;
    this.allowNewUsersOnly = allowNewUsersOnly ?? this.allowNewUsersOnly;
    this.isControl = isControl ?? this.isControl;
  }
}
