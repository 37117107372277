import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';

export interface AdSlot {
  shouldRefresh: boolean;
  lastRefreshTime: number;
  slot: string | null;
  id: string | null;
}

export interface AdsState {
  gadsInit: boolean;
  navAd: AdSlot;
  squareAd: AdSlot;
  srpAdSlot1: AdSlot;
  srpAdSlot2: AdSlot;
  prebidInit: boolean;
}

const initSlot = (): AdSlot => {
  return {
    shouldRefresh: false,
    lastRefreshTime: Date.now(),
    slot: null,
    id: null, // corresponds to <html> id for this ad slot
  };
};

const initState = (): AdsState => ({
  gadsInit: false,
  navAd: initSlot(),
  squareAd: initSlot(),
  srpAdSlot1: initSlot(),
  srpAdSlot2: initSlot(),
  prebidInit: false,
});

const shouldUpdateAd = (state: AdsState) => {
  const now = Date.now();

  // only care about refreshing navAd
  if (!state.navAd.lastRefreshTime || now - state.navAd.lastRefreshTime >= 10000) {
    return true;
  }
  if (!state.srpAdSlot1.lastRefreshTime || now - state.srpAdSlot1.lastRefreshTime >= 10000) {
    return true;
  }

  if (!state.srpAdSlot2.lastRefreshTime || now - state.srpAdSlot2.lastRefreshTime >= 10000) {
    return true;
  }

  return false;
};

// Type guard for AdsState keys so that we only update slot if type is not a boolean
function isAdSlotKey(key: keyof AdsState): key is Exclude<keyof AdsState, 'gadsInit' | 'prebidInit'> {
  return key !== 'gadsInit' && key !== 'prebidInit';
}

const mapActionsToReducer = {
  [constants.GADS_INIT_SUCCESS]: (state: AdsState) => {
    return {
      ...state,
      gadsInit: true,
    };
  },
  [constants.REFRESH_AD]: (state: AdsState, action: { payload: { force: boolean } }) => {
    if (
      (state.navAd.id || state.srpAdSlot1.id || state.srpAdSlot2.id) &&
      (shouldUpdateAd(state) || action.payload.force)
    ) {
      return {
        ...state,
        navAd: {
          ...state.navAd,
          lastRefreshTime: Date.now(),
          shouldRefresh: true,
        },
        srpAdSlot1: {
          ...state.srpAdSlot1,
          lastRefreshTime: Date.now(),
          shouldRefresh: true,
        },
        srpAdSlot2: {
          ...state.srpAdSlot2,
          lastRefreshTime: Date.now(),
          shouldRefresh: true,
        },
      };
    }

    return state;
  },
  [constants.SET_AD_SLOT]: (state: AdsState, action: { payload: { data: AdSlot; slot: keyof AdsState } }) => {
    const { data, slot } = action?.payload || {};

    if (data && slot && isAdSlotKey(slot)) {
      const newState: AdsState = { ...state };
      const newSlot = {
        ...(state[slot] as AdSlot),
        ...data,
      };

      newState[slot] = newSlot as AdSlot;

      return newState;
    }

    return state;
  },
  [constants.PREBID_INIT]: (state: AdsState, action: { payload: { loaded: boolean } }) => {
    const { loaded } = action?.payload || false;
    return {
      ...state,
      prebidInit: loaded,
    };
  },
};

const ads = reduxUtils.createReducer(mapActionsToReducer, initState());
export default ads;
