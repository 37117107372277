/**
 * Experiment Constants
 *
 * This file contains constants used for A/B testing experiments across the HotPads web application.
 *
 * IMPORTANT: Ensure any updates or changes to these constants are reflected in:
 * - apps/hotpads-web/app/shared/experiments/index.ts
 *
 * Force Enrollment:
 * You can force enroll in either an experiment or control bucket by using special query parameters
 * with the VALUE (not the KEY) of this object:
 *
 * For example, if our constants look like this:
 * export default {
 *   HOTPADS_WEB_LISTED_BY_POSITION: 'HOTPADS_WEB_LISTING_AGENT_POSITION_2',
 * }
 *
 * To force enroll in experiment:
 * - https://hotpads.com?HPWEB_EXP=HOTPADS_WEB_LISTING_AGENT_POSITION_2
 *
 * To force enroll in control:
 * - https://hotpads.com?HPWEB_CONTROL=HOTPADS_WEB_LISTING_AGENT_POSITION_2
 */
export default {
  HOTPADS_WEB_HDP_AA_TEST: 'HOTPADS_WEB_AA_TEST_4',
  HOTPADS_WEB_LISTED_BY_POSITION: 'HOTPADS_WEB_LISTING_AGENT_POSITION_2',
  HOTPADS_WEB_SIMILAR_LISTINGS_ONE_CLICK_SUBMIT: 'HOTPADS_WEB_SIMILAR_LISTINGS_ONE_CLICK_SUBMIT',
  HOTPADS_WEB_DOT_MAP_TEST: 'HOTPADS_WEB_DOT_MAP_TEST',
};
