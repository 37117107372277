import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';
import assign from 'lodash/assign';

export interface SEOState {
  seoFooterLinks: Record<string, unknown>;
}

const initState = (): SEOState => ({
  seoFooterLinks: {},
});

const mapActionsToReducer = {
  [constants.LOAD_SEO_FOOTER_LINKS]: (
    state: SEOState,
    action: {
      payload: {
        seoFooterLinks: object;
      };
    },
  ): SEOState => {
    const { seoFooterLinks } = action.payload;
    return assign({}, state, { seoFooterLinks });
  },
};

const seo = reduxUtils.createReducer(mapActionsToReducer, initState());

export default seo;
