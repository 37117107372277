import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import merge from 'lodash/merge';

import constants from 'app/shared/constants/ConstantsBundle';
import listingDetailsCache from 'app/shared/cache/listingDetailsCache';
import reduxUtils from 'app/shared/utils/reduxUtils';
import { findListingIndex, updateUserItemTypes } from 'app/shared/utils/listingsReducerUtils';
import type { ListingDetails } from 'app/types/listingDetails.type';

export interface ListingGroups {
  viewed: Array<ListingDetails>;
  favorite: Array<ListingDetails>;
  hidden: Array<ListingDetails>;
  inquired: Array<ListingDetails>;
  byCoords: Array<ListingDetails>;
  portfolio: Array<ListingDetails>;
  previewListing: ListingDetails | null;
}

export interface ListingsState {
  listingsByArea: Record<string, unknown>;
  moreListings: number;
  totalListings: number;
  totalBuildings: number;
  numDefaultFilterListings: number;
  listingGroups: ListingGroups;
  nearbyAreas: Array<unknown>;
}

const initState = (): ListingsState => ({
  listingsByArea: {},
  moreListings: 0,
  totalListings: 0, // Number of listings (includes floorplans)
  totalBuildings: 0, // Number of buildings (does not include floorplans)
  numDefaultFilterListings: 0,
  listingGroups: {
    viewed: [],
    favorite: [],
    hidden: [],
    inquired: [],
    byCoords: [],
    portfolio: [],
    previewListing: null,
  },
  nearbyAreas: [],
});

const mapActionsToReducer = {
  [constants.SET_PREVIEW_LISTING]: (state: ListingsState, action: { previewListing: string }) => {
    const previewListing = action.previewListing;

    return assign({}, state, {
      listingGroups: assign({}, state.listingGroups, {
        previewListing,
      }),
    });
  },
  [constants.SET_VIEWED_LISTINGS]: (state: ListingsState, action: { payload: { listings: Array<ListingDetails> } }) => {
    const listingsArray = action.payload.listings;

    listingsArray.forEach((listing) => {
      listingDetailsCache.updateUserItemType(listing.maloneLotIdEncoded, constants.VIEWED);
    });

    return assign({}, state, {
      listingGroups: assign({}, state.listingGroups, {
        viewed: listingsArray,
      }),
    });
  },
  [constants.SET_FAVORITE_LISTINGS]: (
    state: ListingsState,
    action: { payload: { listings: Array<ListingDetails> } },
  ) => {
    const listingsArray = action.payload.listings;

    listingsArray.forEach((listing) => {
      listingDetailsCache.updateUserItemType(listing.maloneLotIdEncoded, constants.FAVORITE);
    });

    const updatedListings = listingsArray.map((listing) => ({
      ...listing,
      userItemTypes: updateUserItemTypes(listing.userItemTypes, constants.FAVORITE, 'add'),
    }));

    return assign({}, state, {
      listingGroups: assign({}, state.listingGroups, {
        favorite: updatedListings,
      }),
    });
  },
  [constants.SET_HIDDEN_LISTINGS]: (state: ListingsState, action: { payload: { listings: Array<ListingDetails> } }) => {
    const listingsArray = action.payload.listings;

    listingsArray.forEach((listing) => {
      if (listing.userItemTypes.indexOf(constants.HIDDEN) === -1) {
        listing.userItemTypes.push(constants.HIDDEN);
      }

      listingDetailsCache.updateUserItemType(listing.maloneLotIdEncoded, constants.HIDDEN);
    });

    return assign({}, state, {
      listingGroups: assign({}, state.listingGroups, {
        hidden: listingsArray,
      }),
    });
  },
  [constants.SET_INQUIRED_LISTINGS]: (
    state: ListingsState,
    action: { payload: { listings: Array<ListingDetails> } },
  ) => {
    const listingsArray = action.payload.listings;

    listingsArray.forEach((listing) => {
      if (listing.userItemTypes.indexOf(constants.INQUIRY) === -1) {
        listing.userItemTypes.push(constants.INQUIRY);
      }

      listingDetailsCache.updateUserItemType(listing.maloneLotIdEncoded, constants.INQUIRY);
    });

    return assign({}, state, {
      listingGroups: assign({}, state.listingGroups, {
        inquired: listingsArray,
      }),
    });
  },
  [constants.CLEAR_PREVIEW_LISTING]: (state: ListingsState) => {
    return assign({}, state, {
      listingGroups: assign({}, state.listingGroups, {
        previewListing: null,
      }),
    });
  },
  [constants.USER_ITEM_OPTIMISTIC_TOGGLE]: (
    state: ListingsState,
    action: { payload: { listing: ListingDetails; type: string; action: string } },
  ) => {
    const listing = action.payload.listing;

    if (!listing.maloneLotIdEncoded || !listing.geo) {
      return state;
    }

    const type = action.payload.type; // favorite, viewed, hidden, inquiry, etc.
    const addOrRemove = action.payload.action; // add, remove
    let previewListing = state.listingGroups.previewListing;
    let tempListingToUpdate;
    let indexInByCoords: number = -1;

    // Update locally in favorite cache
    const indexInFavArray = findListingIndex(state.listingGroups.favorite, listing);

    if (indexInFavArray > -1) {
      state.listingGroups.favorite = [...state.listingGroups.favorite];
      tempListingToUpdate = assign({}, state.listingGroups.favorite[indexInFavArray]);
      tempListingToUpdate.userItemTypes = updateUserItemTypes(tempListingToUpdate.userItemTypes, addOrRemove, type);
      state.listingGroups.favorite[indexInFavArray] = tempListingToUpdate;
    }

    // Update locally in hidden cache
    const indexInHiddenArray = findListingIndex(state.listingGroups.hidden, listing);

    if (indexInHiddenArray > -1) {
      state.listingGroups.hidden = [...state.listingGroups.hidden];
      tempListingToUpdate = assign({}, state.listingGroups.hidden[indexInHiddenArray]);
      tempListingToUpdate.userItemTypes = updateUserItemTypes(tempListingToUpdate.userItemTypes, addOrRemove, type);
      state.listingGroups.hidden[indexInHiddenArray] = tempListingToUpdate;
    }

    // Update locally in viewed cache
    const indexInViewedArray = findListingIndex(state.listingGroups.viewed, listing);

    if (indexInViewedArray > -1) {
      state.listingGroups.viewed = [...state.listingGroups.viewed];
      tempListingToUpdate = assign({}, state.listingGroups.viewed[indexInViewedArray]);
      tempListingToUpdate.userItemTypes = updateUserItemTypes(tempListingToUpdate.userItemTypes, addOrRemove, type);
      state.listingGroups.viewed[indexInViewedArray] = tempListingToUpdate;
    }

    // Update locally in inquired cache
    const indexInInquiredArray = findListingIndex(state.listingGroups.inquired, listing);

    if (type === constants.INQUIRY && indexInInquiredArray > -1) {
      state.listingGroups.inquired = [...state.listingGroups.inquired];
      tempListingToUpdate = assign({}, state.listingGroups.inquired[indexInInquiredArray]);
      tempListingToUpdate.userItemTypes = updateUserItemTypes(tempListingToUpdate.userItemTypes, addOrRemove, type);
      state.listingGroups.inquired[indexInInquiredArray] = tempListingToUpdate;
    } else if (type === constants.INQUIRY) {
      state.listingGroups.inquired = [...state.listingGroups.inquired, listing];
    }

    // Update locally in previewListing
    if (previewListing && listing.maloneLotIdEncoded === previewListing.maloneLotIdEncoded) {
      previewListing = merge({}, previewListing);
      previewListing.userItemTypes = updateUserItemTypes(previewListing.userItemTypes, addOrRemove, type);
      state.listingGroups.previewListing = previewListing;
    }

    // Update locally in portfolio
    const indexInPortfolioArray = findListingIndex(state.listingGroups.portfolio, listing);

    if (indexInPortfolioArray > -1) {
      state.listingGroups.portfolio = [...state.listingGroups.portfolio];
      tempListingToUpdate = assign({}, state.listingGroups.portfolio[indexInPortfolioArray]);
      tempListingToUpdate.userItemTypes = updateUserItemTypes(tempListingToUpdate.userItemTypes, addOrRemove, type);
      state.listingGroups.portfolio[indexInPortfolioArray] = tempListingToUpdate;
    }

    // update locally in byCoords
    forEach(state.listingGroups.byCoords, (obj, i) => {
      if (obj.maloneLotIdEncoded === listing.maloneLotIdEncoded) {
        indexInByCoords = i;
      }
    });
    if (indexInByCoords > -1) {
      state.listingGroups.byCoords = [...state.listingGroups.byCoords];

      // set the actual listing within byCoords array to a new object.
      // ensures Listings.jsx -> ListingWrapper.jsx re-renders
      tempListingToUpdate = assign({}, state.listingGroups.byCoords[indexInByCoords]);
      tempListingToUpdate.userItemTypes = updateUserItemTypes(tempListingToUpdate.userItemTypes, addOrRemove, type);
      state.listingGroups.byCoords[indexInByCoords] = tempListingToUpdate;
    }

    // To do: need to clean this fn up!
    return assign({}, state);
  },
  [constants.FETCH_NUMBER_OF_LISTINGS]: (state: ListingsState, action: { payload: { totalListings: number } }) => {
    const totalListings = action.payload.totalListings;

    return assign({}, state, {
      totalListings,
    });
  },
  [constants.FETCH_LISTINGS_SUCCESS]: (
    state: ListingsState,
    action: {
      payload: {
        listings: Array<ListingDetails>;
        listingGroup: string;
        totalListings: number;
        totalBuildings: number;
      };
    },
  ) => {
    const newListings = action.payload.listings;
    const listingGroup = action.payload.listingGroup;
    const totalListings = action.payload.totalListings;
    const totalBuildings = action.payload.totalBuildings;
    const listingGroupsObject: Record<string, Array<ListingDetails>> = {};

    listingGroupsObject[listingGroup] = newListings;

    return assign({}, state, {
      listingGroups: assign({}, state.listingGroups, listingGroupsObject),
      totalListings,
      totalBuildings,
    });
  },
  [constants.RESET_MAP_LISTING_CACHE]: (state: ListingsState) => {
    return assign({}, state, {
      moreListings: initState().moreListings,
      listingGroups: assign(
        {},
        {
          viewed: [],
          favorite: state.listingGroups.favorite, // no need to assign; same, non-mutated objects!
          hidden: [],
          inquired: [],
          byCoords: [],
          previewListing: state.listingGroups.previewListing,
        },
      ),

      // do not need to reset the building cache
    });
  },
  [constants.CLEAR_LISTING_CACHE]: (state: ListingsState) => {
    return assign({}, state, {
      moreListings: initState().moreListings,
      listingGroups: initState().listingGroups,
    });
  },
  [constants.SET_LISTING_ENGINE_STORE_BOOL]: (
    state: ListingsState,
    action: {
      payload: {
        name: string;
        bool: boolean;
      };
    },
  ) => {
    const newState: Record<string, boolean> = {};
    newState[action.payload.name] = action.payload.bool;
    return assign({}, state, newState);
  },
  [constants.SET_NUM_DEFAULT_FILTER_LISTINGS]: (
    state: ListingsState,
    action: {
      payload: {
        numDefaultFilterListings: number;
      };
    },
  ) => {
    const numDefaultFilterListings = action.payload;
    return assign({}, state, numDefaultFilterListings);
  },
  [constants.SET_NEARBY_LISTINGS]: (
    state: ListingsState,
    action: {
      payload: {
        nearbyAreas: Array<unknown>;
      };
    },
  ) => {
    const nearbyAreas = action.payload;
    return assign({}, state, nearbyAreas);
  },
  [constants.CLEAR_DEFAULT_LISTING_COUNT]: (state: ListingsState) => {
    return assign({}, state, {
      numDefaultFilterListings: 0,
    });
  },
  [constants.FETCH_LISTINGS_PORTFOLIO_SUCCESS]: (
    state: ListingsState,
    action: {
      payload: Array<ListingDetails>;
    },
  ) => {
    // const listingsArray = action.payload.listings;

    // listingsArray.forEach((listing) => {
    //     listingDetailsCache.updateUserItemType(listing.maloneLotIdEncoded, constants.FAVORITE);
    // });

    const payload = action.payload;

    return assign({}, state, {
      listingGroups: assign({}, state.listingGroups, {
        portfolio: payload,
      }),
    });
  },
  [constants.FETCH_LISTINGS_PORTFOLIO_RESET]: (state: ListingsState) => {
    return assign({}, state, {
      listingGroups: assign({}, state.listingGroups, {
        portfolio: [],
      }),
    });
  },
};

const listings = reduxUtils.createReducer(mapActionsToReducer, initState());

export default listings;
