import type { ElementType, FC } from 'react';
import React from 'react';
import omit from 'lodash/omit';
import * as S from './styles';

export interface ResponsiveOptions {
  smAndUp?: S.TextSizeKeys;
  mdAndUp?: S.TextSizeKeys;
  lgAndUp?: S.TextSizeKeys;
  xlAndUp?: S.TextSizeKeys;
}

export interface TextProps {
  children?: React.ReactNode;
  className?: string;
  htmlTag?: ElementType;
  id?: string;
  responsive?: Record<string, ResponsiveOptions>;
  role?: string;
  size?: S.TextSizeKeys;
  style?: React.CSSProperties;
}

const Text: FC<TextProps> = ({ size, htmlTag = 'span', className = '', children, responsive = {}, ...rest }) => {
  const CustomTag = htmlTag || 'span';

  // Omitting specific props to pass the rest to the styled component
  const omittedProps = omit(rest, ['children', 'className', 'htmlTag', 'responsive', 'size']);

  return (
    <S.Text as={CustomTag} className={className} size={size} responsive={responsive} {...omittedProps}>
      {children}
    </S.Text>
  );
};

export default Text;
