import type { Logger } from '@zg-rentals/logger-browser';
import { DragnetJsExceptionCategory, type JsHttpRequestRecordDto } from '@zg-rentals/ts-dragnet-client';
import type { LogHttpRequest } from '@zg-rentals/monitor-base';
import { generateDragnetHttpRequest, MonitorReporter } from '@zg-rentals/monitor-base';
import { DragnetBrowser } from '@zg-rentals/dragnet-browser';

export type ExceptionReporterArgs = {
  logger?: Logger;
  appName: string;
  appVersion: string;
};

function getShortUserAgent() {
  if (typeof navigator === 'undefined') return 'server';

  const userAgent = navigator.userAgent;
  let browser, os;

  // Detect browser
  if (userAgent.indexOf('Firefox') > -1) {
    browser = 'Firefox';
  } else if (userAgent.indexOf('SamsungBrowser') > -1) {
    browser = 'Samsung Internet';
  } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
    browser = 'Opera';
  } else if (userAgent.indexOf('Trident') > -1) {
    browser = 'Internet Explorer';
  } else if (userAgent.indexOf('Edge') > -1) {
    browser = 'Edge';
  } else if (userAgent.indexOf('Chrome') > -1) {
    browser = 'Chrome';
  } else if (userAgent.indexOf('Safari') > -1) {
    browser = 'Safari';
  } else {
    browser = 'Unknown Browser';
  }

  // Detect OS
  if (userAgent.indexOf('Win') > -1) {
    os = 'Windows';
  } else if (userAgent.indexOf('Mac') > -1) {
    os = 'MacOS';
  } else if (userAgent.indexOf('X11') > -1) {
    os = 'UNIX';
  } else if (userAgent.indexOf('Linux') > -1) {
    os = 'Linux';
  } else if (userAgent.indexOf('Android') > -1) {
    os = 'Android';
  } else if (userAgent.indexOf('like Mac') > -1) {
    os = 'iOS';
  } else {
    os = 'Unknown OS';
  }

  return `${browser}_on_${os}`.replace(/\s+/g, '_').toLowerCase();
}

function getSearchObject() {
  return Object.fromEntries(new URLSearchParams(window.location.search));
}

function generateHttpRequestFromWindow(context?: Record<string, unknown>): LogHttpRequest {
  const fullContext = context ?? {};
  if (fullContext.userAgent) {
    fullContext.ua = fullContext.userAgent;
    delete fullContext.userAgent;
  }

  return {
    url: window.location.href,
    method: 'GET',
    params: getSearchObject(),
    body: JSON.stringify(fullContext),
  };
}

export class ExceptionReporter extends MonitorReporter {
  appName: string;
  appVersion: string;
  dragnet: DragnetBrowser;

  constructor({ appName, logger, appVersion }: ExceptionReporterArgs) {
    super({ logger, reporterName: 'ExceptionReporter' });

    this.appName = appName;
    this.appVersion = appVersion;
    this.logger = logger;
    this.dragnet = new DragnetBrowser({
      appName: appName,
      serverName: getShortUserAgent(),
      appVersion: appVersion,
    });
  }

  gauges(): Promise<void> {
    return Promise.resolve(undefined);
  }

  measurementWithGauges(): Promise<void> {
    return Promise.resolve(undefined);
  }

  count(): Promise<void> {
    return Promise.resolve(undefined);
  }

  async onError(err: {
    error: Error;
    httpRequest?: LogHttpRequest;
    context?: Record<string, unknown>;
    isErrorBoundary?: boolean;
  }) {
    const errHttpRequest = err.httpRequest;

    let httpRequest: JsHttpRequestRecordDto | undefined = undefined;

    try {
      httpRequest = generateDragnetHttpRequest(errHttpRequest ?? generateHttpRequestFromWindow(err.context ?? {}));
    } catch (e) {
      this.logger?.error('Error while generating dragnet request ' + e.stack);
    }

    await this.dragnet.reportError({
      httpRequest,
      error: err.error,
      category: err.isErrorBoundary
        ? DragnetJsExceptionCategory.BOUNDARY_BROWSER_ERROR
        : DragnetJsExceptionCategory.BROWSER_ERROR,
    });
  }

  onInitialize() {
    return undefined;
  }

  onRequest(): void {}
}
