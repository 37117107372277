import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import type { Breadcrumb } from 'app/types/breadcrumb.type';
import type Coordinates from 'app/shared/models/Coordinates';

const logger = getGlobalLogger('reducers/geolocation');

export interface GeolocationArea {
  DATA_MODEL: string;
  id: string;
  resourceId: string;
  name: string;
  fullName: string;
  type: string;
  uriV2: string;
  breadcrumbs: Array<Breadcrumb>;
  coordinates: Coordinates;
  minLat: number;
  maxLat: number;
  minLon: number;
  maxLon: number;
}

export interface GeolocationState {
  area: GeolocationArea;
  areas: Record<string, unknown>;
}

const initState = (): GeolocationState => ({
  area: {
    DATA_MODEL: 'Area',
    id: '117776782',
    resourceId: 'new-york-ny',
    name: 'New York',
    fullName: 'New York, NY',
    type: 'city',
    uriV2: '/new-york-ny/apartments-for-rent',
    breadcrumbs: [],
    coordinates: {
      lon: -73.979681,
      lat: 40.697488,
    },
    minLat: 40.477399,
    maxLat: 40.917577,
    minLon: -74.25909,
    maxLon: -73.700272,
  },
  // TODO: Optimize looking up area by lat/lon
  // cache geohash → [areas]
  areas: {},
});

const mapActionsToReducer = {
  [constants.GEOLOCATION_USER_AREA]: (state: GeolocationState, action: { type: string; area?: GeolocationArea }) => {
    logger?.trace(action, 'GEOLOCATION_USER_AREA');
    const area = action?.area || {};
    if (isEmpty(area)) {
      return merge({}, state);
    }

    return merge({}, state, {
      area,
    });
  },
};

const reducer = reduxUtils.createReducer(mapActionsToReducer, initState());

export default reducer;
