import assign from 'lodash/assign';
import constants from 'app/shared/constants/ConstantsBundle';
import merge from 'lodash/merge';
import reduxUtils from 'app/shared/utils/reduxUtils';

import type Coordinates from 'app/shared/models/Coordinates';
import type { Breadcrumb } from 'app/types/breadcrumb.type';

interface ListingCounts {
  'apartments-for-rent': number;
  'houses-for-rent': number;
  'condos-for-rent': number;
  'sublets-for-rent': number;
  'townhomes-for-rent': number;
  'duplexes-for-rent': number;
}

interface Article {
  areaId: number;
  body: string;
  title: string;
}

interface AreaFacts {
  avgRent: number | null;
  diffFromLastMonth: number | null;
  foreclosuresPerHousehold: number | null;
  listingCounts: ListingCounts | null;
  medianAge: number | null;
  medianHouseholdIncome: number | null;
  medianRent: number | null;
  medianRentAsPercentOfIncome: number | null;
  perCapitaIncome: number | null;
  percentRenters: number | null;
  population: number | null;
  populationDensity: number | null;
  squareMiles: number | null;
}

export interface AreaDetails {
  id: string;
  resourceId: string;
  name: string;
  type: string;
  fullName: string;
  uriV2: string;
  state: string;
  city: string;
  neighborhood: string | null;
  zip: string | null;
  county: string;
  minLat: number;
  maxLat: number;
  minLon: number;
  maxLon: number;
  breadcrumbs: Array<Breadcrumb>;
  coordinates: Coordinates;
}

interface PriceHistogram {
  data: Array<{ min: number }>;
  maxCount: number | null;
  median: number | null;
}

export interface AreaState {
  area: AreaDetails;
  within: Record<string, Array<AreaDetails>>;
  facts: AreaFacts;
  factsByBeds: Record<string, AreaFacts>;
  articles: Array<Article>;
  staticMapPlaceholders: Record<number, string>;
  priceHistogram: PriceHistogram;
  ratingsAndReviews: Record<string, unknown>;
  blogPosts?: Record<string, unknown>;
  areaMinPrice?: number | null;
  areaMaxPrice?: number | null;
  sitemap?: Record<string, unknown>;
}

const initState = (): AreaState => ({
  area: {} as AreaDetails,
  within: {},
  facts: {
    avgRent: null,
    diffFromLastMonth: null,
    foreclosuresPerHousehold: null,
    listingCounts: null,
    medianAge: null,
    medianHouseholdIncome: null,
    medianRent: null,
    medianRentAsPercentOfIncome: null,
    perCapitaIncome: null,
    percentRenters: null,
    population: null,
    populationDensity: null,
    squareMiles: null,
  },
  factsByBeds: {},
  articles: [],
  staticMapPlaceholders: {},
  priceHistogram: {
    data: [],
    maxCount: null,
    median: null,
  },
  ratingsAndReviews: {},
});

const mapActionsToReducer = {
  [constants.CLEAR_CURRENT_AREA]: () => {
    return assign({}, initState(), {
      area: {} as AreaDetails,
    });
  },
  [constants.LOAD_AREA_SUCCESS]: (state: AreaState, action: { payload: { area: AreaDetails } }) => {
    const newArea = action.payload.area;

    // Reset the state with new area, preserve geoip and facts and factsByBeds.
    return assign({}, initState(), {
      area: assign({}, newArea),
      articles: [...state.articles],
      facts: assign({}, state.facts),
      factsByBeds: assign({}, state.factsByBeds),
      staticMapPlaceholders: assign({}, state.staticMapPlaceholders),
      priceHistogram: assign({}, state.priceHistogram),
      ratingsAndReviews: assign({}, state.ratingsAndReviews),
    });
  },
  [constants.LOAD_WITHIN_AREAS]: (
    state: AreaState,
    action: { payload: { within: Record<string, Array<AreaDetails>> } },
  ) => {
    const { within } = action.payload;

    return assign({}, state, {
      within: merge({}, state.within, within),
    });
  },
  [constants.LOAD_FACTS]: (state: AreaState, action: { payload: { facts: AreaFacts } }) => {
    const { facts } = action.payload;

    return assign({}, state, {
      facts: assign({}, facts),
    });
  },
  [constants.LOAD_FACTS_BY_BEDS]: (
    state: AreaState,
    action: { payload: { factsByBeds: Record<string, AreaFacts> } },
  ) => {
    const { factsByBeds } = action.payload;

    return assign({}, state, {
      factsByBeds: assign({}, factsByBeds),
    });
  },
  [constants.LOAD_ARTICLES]: (state: AreaState, action: { payload: { articles: Array<Article> } }) => {
    const { articles } = action.payload;

    return assign({}, state, {
      articles: [...articles],
    });
  },
  [constants.LOAD_BLOG_POSTS]: (state: AreaState, action: { payload: { blogPosts: Record<string, unknown> } }) => {
    const { blogPosts } = action.payload;

    return assign({}, state, {
      blogPosts: assign({}, blogPosts),
    });
  },
  [constants.FETCH_AREA_MIN_MAX_PRICE]: (
    state: AreaState,
    action: { payload: { areaMinPrice: number; areaMaxPrice: number } },
  ) => {
    return assign({}, state, {
      areaMinPrice: action.payload.areaMinPrice || null,
      areaMaxPrice: action.payload.areaMaxPrice || null,
    });
  },
  [constants.LOAD_AREA_PRICE_HISTOGRAM]: (
    state: AreaState,
    action: { payload: { priceHistogram: PriceHistogram } },
  ) => {
    const { priceHistogram } = action.payload;

    return assign({}, state, {
      priceHistogram: assign({}, priceHistogram),
    });
  },
  [constants.LOAD_HTML_SITEMAP]: (state: AreaState, action: { payload: { sitemap: Record<string, unknown> } }) => {
    const { sitemap } = action.payload;
    return assign({}, state, {
      sitemap: assign({}, sitemap),
    });
  },
  [constants.SAVE_STATIC_MAP_PLACEHOLDERS]: (state: AreaState, action: { payload: Record<number, string> }) => {
    return assign({}, state, {
      staticMapPlaceholders: assign({}, action.payload),
    });
  },
};

const area = reduxUtils.createReducer(mapActionsToReducer, initState());

export default area;
