import type { Options } from '@zg-rentals/http-client';
import { HTTPError } from '@zg-rentals/http-client';
import { getClient } from './client';
import { type ApiRequestInterface, DatarouterError } from '@zg-rentals/web-base';

type ApiResponseSuccess<T> = {
  success: true;
  response: T;
};

type ApiResponseError = {
  success: false;
  error: unknown;
};

type ApiResponseDto<T> = ApiResponseSuccess<T> | ApiResponseError;

export type EnsureExtendsApiResponseDto<T extends ApiResponseDto<unknown>> = T;

async function withHttpErrorHandling<T>(fn: () => Promise<T>): Promise<T> {
  try {
    return await fn();
  } catch (err) {
    const text = await (err instanceof HTTPError
      ? err.response.text().catch(() => '<empty>')
      : (err?.message ?? 'unknown error'));

    if (err instanceof HTTPError) {
      throw new DatarouterError(text, err.response.status);
    } else {
      throw new DatarouterError(text, -1);
    }
  }
}

export async function httpApiRequest<G, P, R>(
  { getParams, postData, endpoint, method, isFormData, contextPath, unwrapApiLayerResponse }: ApiRequestInterface<G, P>,
  client = getClient(),
): Promise<R> {
  const options: Options = { method };

  if (getParams) {
    // Note: any is an escape hatch here just to help us filter out null/undefined values as
    // they are serialized as `null` or `undefined` directly in the query string, which is bad
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options.searchParams = Object.entries(getParams).reduce<Record<string, any>>((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  if (postData) {
    if (isFormData) {
      // If formData, coerce body Object to FormData and include (proper headers set implicitly)
      options.body = Object.entries(postData).reduce((formData, [key, value]) => {
        formData.append(key, value as string | Blob);
        return formData;
      }, new FormData());
    } else {
      // If not formData, just include as json body (proper headers set implicitly)
      options.json = postData;
    }
  }

  // note: collapse multiple slashes into one
  let url = `${contextPath}/${endpoint}`.replace(/\/+/g, '/');
  const response = await withHttpErrorHandling(() =>
    client.extend(({ prefixUrl }) => {
      // Note: prefixUrl requires `url` not to start with `/`
      if (prefixUrl && url.startsWith('/')) {
        url = url.slice(1);
      }

      return { prefixUrl };
    })(url, options),
  );

  const isJson = response.headers.get('content-type')?.includes('application/json');
  if (!isJson) {
    return response as R;
  }

  if (!unwrapApiLayerResponse) {
    return response.json<R>();
  }

  const apiResponse = await response.json<ApiResponseDto<R>>();
  if (apiResponse.success) {
    return apiResponse.response;
  } else {
    // Note: casting required for modules/apps that don't enable full strict mode
    throw (apiResponse as ApiResponseError).error;
  }
}
