import type { KyInstance, Options as KyOptions } from 'ky';
import ky, { HTTPError } from 'ky';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { getGlobalMonitor } from '@zg-rentals/monitor-base';
import { logError } from '@zg-rentals/log-error';
import { makeModuleInstance } from '@zg-rentals/util';
import { memoize } from '@zg-rentals/zdash';

export type Client = KyInstance;
export type Options = KyOptions;
export { HTTPError };

const StartTime = Symbol('StartTime');

export const createClient = ({
  logging,
  monitoring,
  ...options
}: KyOptions & {
  logging?: {
    // milliseconds to consider a request (with a response) "slow" and log to splunk
    slowRequestTimeout: number;
  };
  monitoring?: boolean;
} = {}): Client => {
  const logger = memoize(() => getGlobalLogger('http-client'));
  const monitor = memoize(() => (monitoring ? getGlobalMonitor() : undefined));

  return ky
    .create({
      hooks: {
        beforeRequest: [
          (request, requestOptions) => {
            monitor()?.count({ name: 'httpClient beforeRequest volume total', amount: 1 });

            monitor()?.count({
              name: `httpClient beforeRequest volume method ${request.method} total`,
              amount: 1,
            });

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (requestOptions as any)[StartTime] = Date.now();

            logger()?.debug(
              {
                url: request.url,
                method: request.method,
              },
              'beforeRequest',
            );
          },
        ],

        afterResponse: [
          (request, requestOptions, response) => {
            monitor()?.count({ name: 'httpClient afterResponse volume total', amount: 1 });

            monitor()?.count({
              name: `httpClient afterResponse volume method ${request.method} total`,
              amount: 1,
            });

            monitor()?.count({
              name: `httpClient afterResponse volume statusCode ${response.status} total`,
              amount: 1,
            });

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const latencyMs = (Date.now() - (requestOptions as any)[StartTime]) as number;

            monitor()?.gauges({
              name: 'httpClient afterResponse latencyMs',
              amount: latencyMs,
            });

            monitor()?.gauges({
              name: `httpClient afterResponse latencyMs method ${request.method}`,
              amount: latencyMs,
            });

            if (logging?.slowRequestTimeout != null && latencyMs >= logging.slowRequestTimeout) {
              logger()?.warn(
                {
                  url: request.url,
                  method: request.method,
                  status: response.status,
                  latencyMs,
                },
                'afterResponse slow request',
              );
            } else {
              logger()?.debug(
                {
                  url: request.url,
                  method: request.method,
                  status: response.status,
                  latencyMs,
                },
                'afterResponse',
              );
            }
          },
        ],

        beforeError: [
          (error) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const latencyMs = (Date.now() - (error.options as any)[StartTime]) as number;

            monitor()?.count({ name: 'httpClient beforeError volume total', amount: 1 });

            monitor()?.count({
              name: `httpClient beforeError volume method ${error.request?.method ?? 'UNKNOWN'} total`,
              amount: 1,
            });

            monitor()?.count({
              name: `httpClient beforeError volume statusCode ${error.response?.status ?? 'UNKNOWN'} total`,
              amount: 1,
            });

            monitor()?.gauges({
              name: 'httpClient beforeError latencyMs',
              amount: latencyMs,
            });

            monitor()?.gauges({
              name: `httpClient beforeError latencyMs method ${error.request?.method ?? 'UNKNOWN'} total`,
              amount: latencyMs,
            });

            logError({
              error,
              context: {
                url: error.request.url,
                method: error.request.method,
                status: error.response.status,
                latencyMs,
                traceparent: error.response.headers.get('traceparent'),
                eid: error.response.headers.get('x-eid'),
              },
              errorType: 'HTTPError',
            });

            return error;
          },
        ],
      },
    })
    .extend(options);
};

export const { get: getClient, set: setClient } = makeModuleInstance(
  createClient({ logging: { slowRequestTimeout: 30 * 1000 }, monitoring: true }),
);
