import { BootstrapBrowserBuilder } from '@zg-rentals/rp-bootstrap-browser';

export const bootstrap = new BootstrapBrowserBuilder('hotpads-web', Number(__BUILD_NUMBER__))
  .withLoggerOptions({
    logPath: '/log',
    name: 'hotpads-web',
    level: 'info',
  })
  .withDatadogPlugin('c1472ba7-3d5d-44d4-b19d-7ab3649680b1', 'pub1aa8dca06cfed3ccee4832929875e69a')
  .build();
