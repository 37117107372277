/* eslint-disable @typescript-eslint/ban-types */

import { makeModuleInstance, warningBanner } from '@zg-rentals/util';

export type Tags = Record<string, unknown>;

export type Trace = {
  traceId: string;
  sessionId: string;
  reqId?: string;
  doSave: boolean;
  tags: Tags;
};

export type Span = {
  spanId: string;
  traceId: string;
  finish: (error?: Error) => void;
  getTags: () => Tags;
  setTags: (tags: Tags) => void;
};

// ASYNC_FN_MATCHER matches any stringified function that accepts one or more arguments/callbacks
// YES: (arg) => { ... }
// YES: arg => { ... }
// YES: function hello ( goodbye ) { ... }
// NO: ( ) => { ... }
// NO: function test() { ... }
// NO: function( ) { ... }
export const ASYNC_FN_MATCHER = /^(\(?\s*\w+\s*\)?\s*=>|function(\s+\w*\s*)?\(\s*\w)/;

export abstract class Tracer {
  static isAsyncFunction(fn: Function) {
    return ASYNC_FN_MATCHER.test(fn.toString());
  }

  abstract trace<T>(fn: (...args: Array<unknown>) => T, name: string, tags?: Tags): T;
  abstract getCurrentTrace(): Trace;
  abstract getCurrentSpan(): Span | undefined;
  abstract makeTraceID(): string;

  wrap<T extends Function>(fn: T, name = fn.name, tags?: Tags) {
    return ((...args: Array<unknown>) => {
      return this.trace(() => fn.apply(this, args), name, tags);
    }) as unknown as T;
  }

  setSpanTag(key: string, value: unknown) {
    const span = this.getCurrentSpan();
    if (span) {
      span.setTags({ [key]: value });
    }
  }

  getSpanTag(key: string) {
    const span = this.getCurrentSpan();
    return span?.getTags()[key];
  }

  getCurrentTags(): Tags {
    const trace = this.getCurrentTrace();
    const span = this.getCurrentSpan();
    return {
      ...trace.tags,
      ...span?.getTags(),
    };
  }

  setSaveTrace(doSave: boolean): void {
    const trace = this.getCurrentTrace();
    trace.doSave = doSave;
  }
}

export const { get: getGlobalTracer, set: setGlobalTracer } = makeModuleInstance<Tracer | undefined>(undefined);
export { warningBanner };
