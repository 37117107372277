import dateUtils from 'app/shared/utils/dateUtils';

const addScheduledDateTimeToAvailableTours = (
  availableDatesTimes: Array<string>,
  scheduledDateWithOrWithoutTime: string,
) => {
  const scheduledDateTime = dateUtils.appendTimeISOSubstringIfMissing(scheduledDateWithOrWithoutTime);
  const doNotAddScheduledTour =
    availableDatesTimes.includes(scheduledDateWithOrWithoutTime) || dateUtils.isDateInThePast(scheduledDateTime);

  if (doNotAddScheduledTour) {
    return;
  }

  const dateToAdd = new Date(scheduledDateWithOrWithoutTime);
  const scheduledTourIndex = availableDatesTimes.findIndex(
    (availableDateTime) => dateToAdd < new Date(availableDateTime),
  );
  if (scheduledTourIndex !== -1) {
    availableDatesTimes.splice(scheduledTourIndex, 0, scheduledDateWithOrWithoutTime);
  } else {
    availableDatesTimes.push(scheduledDateWithOrWithoutTime);
  }
};

export type AvailableToursMap = {
  [dateKey: string]: Array<string>;
};

const buildAvailableToursMap = (
  availableDatesWithOrWithoutTimes: Array<string>,
  scheduledDateWithOrWithoutTime: string,
  dateOnly: boolean,
  zoneId: string,
): AvailableToursMap => {
  const availableToursMap: AvailableToursMap = {};

  availableDatesWithOrWithoutTimes.forEach((availableDateWithOrWithoutTime) => {
    const availableDateTime = dateUtils.appendTimeISOSubstringIfMissing(availableDateWithOrWithoutTime);
    const dateAsKey = dateUtils.parseISODateString(availableDateTime, zoneId, 'yyyy-MM-dd') as string;
    if (!(dateAsKey in availableToursMap)) {
      availableToursMap[dateAsKey] = [];
    }

    availableToursMap[dateAsKey].push(availableDateTime);
  });

  if (!scheduledDateWithOrWithoutTime || !scheduledDateWithOrWithoutTime.includes('T') || dateOnly) {
    return availableToursMap;
  }

  const scheduledDateAsKey = dateUtils.parseISODateString(
    scheduledDateWithOrWithoutTime,
    zoneId,
    'yyyy-MM-dd',
  ) as string;
  if (!(scheduledDateAsKey in availableToursMap)) {
    return availableToursMap;
  }

  addScheduledDateTimeToAvailableTours(availableToursMap[scheduledDateAsKey], scheduledDateWithOrWithoutTime);

  return availableToursMap;
};

class AvailableToursForListing {
  availableToursMap: AvailableToursMap;
  zoneId: string;

  /**
   * Object representing all the available tours for a listing.
   *
   * Generates availableToursMap object with the following KEYS and VALUES:
   * VALUE: an ISO standard UTC date string representing an available time for scheduling a tour.
   *   KEY: date string in form of YYYY-MM-DD representing a local date that is mapped to VALUES whose dates
   *        match that local date when said VALUES are converted from UTC to local.
   *
   * If dates only is true, then the VALUES in availableTimes contain dates only, meaning they come with no time provided.
   * In this case, the times for those dates are treated as 12:00 ET. Therefore, we must append 12:00 ET (as UTC)
   * to each of those VALUES to allow for accurate timezone conversions from UTC to local when using Javascript's Date object.
   *
   * If scheduledDateWithOrWithoutTime is provided and the scheduledDateWithOrWithoutTime contains both the date and time
   * and dateOnly is false, then we may or may not need to manually add scheduledDateWithOrWithoutTime back into available tours.
   * This is because some instant tour providers removes an available date/time for all tour types after a user schedules
   * it, but that same user may want to reschedule for that same date/time but for a different tour type - they may not be
   * able to unless we manually add the original scheduled date/time back in. This does not apply for date only scenario.
   *
   * @param availableDatesWithOrWithoutTimes an array of date (UTC) strings in ISO format which may or may not include explicit times.
   * @param dateOnly flag for whether the ISO strings in availableDatesWithOrWithoutTimes are dates only.
   * @param scheduledDateWithOrWithoutTime an ISO date string representing a date (with or w/o time) already scheduled for the user.
   * @param zoneId time zone identifier
   */
  constructor({
    availableDatesWithOrWithoutTimes = [],
    dateOnly = false,
    scheduledDateWithOrWithoutTime = '',
    zoneId = '',
  }: {
    availableDatesWithOrWithoutTimes?: Array<string>;
    dateOnly?: boolean;
    scheduledDateWithOrWithoutTime?: string;
    zoneId?: string;
  } = {}) {
    this.availableToursMap = buildAvailableToursMap(
      availableDatesWithOrWithoutTimes,
      scheduledDateWithOrWithoutTime,
      dateOnly,
      zoneId,
    );
    this.zoneId = zoneId;
  }
}

export default AvailableToursForListing;
