import type { Logger } from '@zg-rentals/logger-browser';
import type { Monitor, StatController } from '@zg-rentals/monitor-base';
import { MonitorPlugin } from '@zg-rentals/monitor-base';
import { datadogRum } from '@datadog/browser-rum';
import { isbot } from 'isbot';

export class DatadogPlugin extends MonitorPlugin {
  constructor(
    private readonly applicationId: string,
    private readonly clientToken: string,
    private readonly service: string,
    private readonly version: string,
  ) {
    super('datadogPlugin');
  }

  onInitialize(monitor: Monitor, logger?: Logger): void | StatController {
    super.onInitialize(monitor, logger);

    if (typeof window !== 'undefined') {
      datadogRum.init({
        applicationId: this.applicationId,
        clientToken: this.clientToken,
        service: this.service,
        version: this.version,
        sessionSampleRate: 20,
        sessionReplaySampleRate: 0,
        trackUserInteractions: false,
        allowedTracingUrls: [window.location.origin],
        site: 'datadoghq.com',
        startSessionReplayRecordingManually: true,
        beforeSend: () => !isbot(window.navigator.userAgent),
      });
    }
  }
}
