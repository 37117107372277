// https://developers.sift.com/docs/curl/javascript-api/overview
import { useEffect } from 'react';
import { logError } from '@zg-rentals/log-error';
import { isDev } from '@zg-rentals/environment-utils';

const BEACON_KEY = '4f0e3321cc';

declare global {
  interface Window {
    _sift?: Array<Array<string>>;
  }
}

export function useSiftScience({ email, isImpersonator }: { email?: string | null; isImpersonator: boolean }) {
  useEffect(() => {
    if (!isDev() && !isImpersonator && email) {
      try {
        window._sift ||= [];
        window._sift.push(
          ['_setAccount', `${BEACON_KEY}`],
          ['_setUserId', email],
          ['_setSessionId', `${Date.now()}`],
          ['_trackPageview'],
        );

        const element = document.createElement('script');
        element.id = 'sift-science';
        element.src = 'https://cdn.sift.com/s.js';
        document.body.appendChild(element);

        return () => {
          document.body.removeChild(element);
        };
      } catch (error) {
        logError({ error, errorType: 'SiftScienceError', context: 'useSiftScience hook error' });
      }
    }
  }, [isImpersonator, email]);
}
