/* eslint-disable @typescript-eslint/no-explicit-any */
function memoize<A extends Array<any>, T>(fn: (...args: A) => T): ((...args: A) => T) & { reset: () => void } {
  const cache = new Map<string, T>();

  const memoized = (...args: A) => {
    const key = JSON.stringify(args);
    const cached = cache.get(key);
    if (cached !== undefined) {
      return cached;
    } else {
      const result = fn(...args);
      cache.set(key, result);
      return result;
    }
  };

  memoized.reset = () => cache.clear();

  return memoized;
}

export { memoize };
