import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';
import merge from 'lodash/merge';

export interface ViewState {
  searchExperience: string;
}

const initState = (): ViewState => ({
  searchExperience: 'list',
});

const mapActionsToReducer = {
  [constants.VIEW_SEARCH_EXPERIENCE]: (state: ViewState, action: { payload: string }): ViewState => {
    let searchExperience = 'list';
    if (action.payload === 'map') {
      searchExperience = action.payload;
    }

    return merge({}, state, { searchExperience });
  },
};

const reducer = reduxUtils.createReducer(mapActionsToReducer, initState());

export default reducer;
