import assign from 'lodash/assign';
import constants from 'app/shared/constants/ClusterSettingConstants';
import isArray from 'lodash/isArray';

// DANGER:
// this file is currently being read by the client. don't include
// cluster values that should not be visible on the client

// Unfortunately, API always returns values as Strings, not native types.
function toPrimitive(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value.toString();
  }
}

function noValue(value) {
  /*eslint-disable no-eq-null */
  /*eslint-disable no-self-compare */
  return value == null || value !== value;
}

function checkRandomThreshold(value = 0) {
  let percent = parseInt(value, 10);
  if (isNaN(percent)) {
    percent = 0;
  }
  const enabled = Math.random() * 100 < percent;

  return enabled;
}

const clusterSettingCache = {
  reload(settings) {
    const newValues = assign({}, this.defaults);

    if (!isArray(settings)) {
      return false;
    }

    // Filter only the constants we use
    settings.forEach((setting) => {
      for (const name in constants) {
        if (constants[name] === setting.name) {
          newValues[setting.name] = toPrimitive(setting.value);
        }
      }
    });

    this.clusterSettingDict = newValues;
  },

  getClusterSettingValue(name) {
    const value = this.clusterSettingDict[name];
    const defaultValue = this.defaults[name];

    return noValue(value) ? defaultValue : value;
  },
};

// constructor
clusterSettingCache.clusterSettingDict = {};
clusterSettingCache.defaults = {};

export default clusterSettingCache;
