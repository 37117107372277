import merge from 'lodash/merge';
import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import RecentSearch from 'app/shared/models/RecentSearch';
import SavedSearch from 'app/shared/models/SavedSearch';
import type { ScheduledTourProps } from 'app/shared/models/ScheduledTour';
import ScheduledTour from 'app/shared/models/ScheduledTour';
import { getGlobalLogger } from '@zg-rentals/logger-base';

const logger = getGlobalLogger('reducers/user');

interface InquiryDataCache {
  isPending: boolean;
  name: string | null;
  email: string | null;
  phone: string | null;
  text: string | null;
}

interface ShareDataCache {
  emailFields: Array<EmailField>;
  phoneFields: Array<PhoneField>;
}

interface EmailField {
  value: string;
}

interface PhoneField {
  value: string;
}

interface UserSearch {
  recent: Record<string, unknown>;
  saved: Array<unknown>;
}

interface NotificationPreferences {
  email: boolean;
  mobile: boolean;
}

interface Subscriptions {
  stopListings: boolean | null;
  stopOther: boolean | null;
}

interface UserPoints {
  activeUserPoint: string | null;
  destinations: Array<unknown>;
}

interface UserInfo {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  passwordSet: boolean | null;
  passwordStatus: string | null;
  roles: Array<string>;
  unverifiedRoles?: Array<string>;
}

interface NotificationOption {
  deviceGroup: string;
  typeGroup: string;
  optedOut: boolean;
}

export interface UserState {
  conversations: Record<string, unknown>;
  csrfToken: string;
  ctaButtonContext: string | null;
  currentSearch: string | null;
  hasOptedOutAdTracking: boolean;
  info: UserInfo;
  inquiryDataCache: InquiryDataCache;
  isVerified: boolean;
  loggedIn: boolean;
  propertyUpdatePreferences: NotificationPreferences;
  recSearchPreferences: NotificationPreferences;
  renterProfile: Record<string, unknown>;
  savedSearchPreferences: NotificationPreferences;
  scheduledTours: Array<unknown>;
  search: UserSearch;
  sessionToken: string;
  shareDataCache: ShareDataCache;
  subscriptions: Subscriptions;
  unreadMsgCount: number;
  userPoints: UserPoints;
  userToken: string;
  serverSideCookies: Record<string, unknown>;
  serverSideSetCookiesArray: Array<unknown>;
}

const initNotificationTypes = (): NotificationPreferences => ({
  email: true,
  mobile: true,
});

const initState = (): UserState => ({
  loggedIn: false,
  hasOptedOutAdTracking: false,
  isVerified: false,
  userToken: '',
  csrfToken: '',
  sessionToken: '',
  info: {
    email: null,
    firstName: null,
    lastName: null,
    passwordSet: null,
    passwordStatus: null,
    roles: [],
  },
  ctaButtonContext: null, // HPWEB-5993: CTA A/B test
  inquiryDataCache: {
    isPending: true,
    name: null,
    email: null,
    phone: null,
    text: null,
  },
  conversations: {},
  unreadMsgCount: 0,
  shareDataCache: {
    emailFields: [{ value: '' }],
    phoneFields: [{ value: '' }],
  },
  search: {
    recent: {},
    saved: [],
  },
  savedSearchPreferences: initNotificationTypes(),
  recSearchPreferences: initNotificationTypes(),
  propertyUpdatePreferences: initNotificationTypes(),
  subscriptions: {
    stopListings: null,
    stopOther: null,
  },
  userPoints: {
    activeUserPoint: null,
    destinations: [],
  },
  renterProfile: {},
  scheduledTours: [],
  currentSearch: null,
  // gets updated for new requests send on the server side
  serverSideCookies: {},
  // gets updated as a set-cookie array
  serverSideSetCookiesArray: [],
});

const mapActionsToReducer = {
  [constants.SET_CURRENT_SEARCH]: (state: UserState, action: { payload: string }) => {
    return assign({}, state, {
      currentSearch: action.payload,
    });
  },
  [constants.USER_LOAD_POINTS]: (state: UserState, action: { payload: Array<unknown> }) => {
    if (!action.payload) {
      logger?.warn('payload not passed into USER_LOAD_POINTS');
    }

    const data = action.payload || [];
    const userPoints = merge({}, state.userPoints, {
      destinations: data,
    });

    return assign({}, state, {
      userPoints,
    });
  },
  [constants.USER_UPDATE_ACTIVE_POINT]: (state: UserState, action: { payload: string }) => {
    const data = action.payload;
    const userPoints = merge({}, state.userPoints, {
      activeUserPoint: data,
    });

    return assign({}, state, {
      userPoints,
    });
  },
  [constants.UPDATE_USER_CREDS]: (state: UserState, action: { payload: { loggedIn: boolean; userToken: string } }) => {
    const { loggedIn, userToken } = action.payload;

    return assign({}, state, {
      loggedIn,
      userToken,
    });
  },
  [constants.UPDATE_SESSION_TOKEN]: (state: UserState, action: { payload: string }) => {
    return assign({}, state, {
      sessionToken: action.payload,
    });
  },
  [constants.UPDATE_CSRF_TOKEN]: (state: UserState, action: { payload: string }) => {
    return assign({}, state, {
      csrfToken: action.payload,
    });
  },
  [constants.UPDATE_USER_INFO]: (state: UserState, action: { payload: { data: UserInfo } }) => {
    if (!action.payload) {
      logger?.warn('data not passed into UPDATE_USER_INFO');
    }
    const data = action.payload.data || {};
    const info = merge({}, state.info, {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      passwordSet: data.passwordSet,
      passwordStatus: data.passwordStatus,
      roles: [...(data.roles || []), ...(data.unverifiedRoles || [])],
    });

    return assign({}, state, {
      info,
      loggedIn: Boolean(data.email),
    });
  },
  [constants.UPDATE_RENTER_PROFILE]: (state: UserState, action: { payload: Record<string, unknown> }) => {
    return assign({}, state, {
      renterProfile: action.payload,
    });
  },
  [constants.UPDATE_SCHEDULED_TOURS_FOR_USER]: (state: UserState, action: { payload: Array<unknown> }) => {
    const scheduledTours = action.payload
      .map((scheduledTour) => {
        return new ScheduledTour(scheduledTour as ScheduledTourProps);
      })
      .filter((scheduledTour) => scheduledTour.schedulingStatus !== 'CANCELLED');

    return assign({}, state, {
      scheduledTours,
    });
  },
  [constants.UPDATE_UNREAD_CONVERSATION_COUNT]: (state: UserState, action: { payload: number }) => {
    return assign({}, state, {
      unreadMsgCount: action.payload,
    });
  },
  [constants.UPDATE_LISTING_CONVERSATION]: (
    state: UserState,
    action: { payload: { aliasEncoded: string; conversation: Record<string, unknown> } },
  ) => {
    const aliasEncoded = action.payload.aliasEncoded;
    const conversation = action.payload.conversation;

    const conversations = merge({}, state.conversations, {
      [aliasEncoded]: conversation,
    });

    return assign({}, state, {
      conversations,
    });
  },
  [constants.LOGIN_SUCCESS]: (
    state: UserState,
    action: {
      payload: {
        data: UserInfo;
        verified: boolean;
        creds?: { verified: boolean };
      };
    },
  ) => {
    if (!action.payload) {
      logger?.warn('data not passed into LOGIN_SUCCESS');
    }
    const data2 = action.payload.data || {};
    const isVerified = action.payload.verified || (action.payload.creds || {}).verified;

    const info2 = merge({}, state.info, {
      email: data2.email,
      firstName: data2.firstName,
      lastName: data2.lastName,
      passwordStatus: data2.passwordStatus,
      passwordSet: data2.passwordSet,

      // isVerified is the determining flag for showing these pages.
      // fine to merge roles?
      roles: [...(data2.roles || []), ...(data2.unverifiedRoles || [])],
    });

    return assign({}, state, {
      isVerified,
      error: null,
      info: info2,
      loggedIn: true,
    });
  },
  [constants.LOGIN_DESTROY]: () => {
    return assign({}, initState());
  },
  [constants.UPDATE_AD_TRACKING]: (state: UserState, action: { payload: { hasOptedOutAdTracking: boolean } }) => {
    return assign({}, state, {
      hasOptedOutAdTracking: action.payload.hasOptedOutAdTracking,
    });
  },
  [constants.UPDATE_SAVED_SEARCHES]: (
    state: UserState,
    action: { payload: { savedSearch: Array<InstanceType<typeof SavedSearch>> } },
  ) => {
    if (!action.payload) {
      logger?.warn('data not passed into UPDATE_SAVED_SEARCHES');
    }

    forEach(action.payload.savedSearch, (search) => {
      if (search instanceof SavedSearch !== true) {
        logger?.warn('incorrect data model passed into UPDATE_SAVED_SEARCHES');
      }
    });
    const searches = action.payload.savedSearch;

    return assign({}, state, {
      search: assign({}, state.search, {
        saved: [...searches],
      }),
    });
  },
  [constants.UPDATE_RECENT_SEARCHES]: (state: UserState, action: { payload: { recentSearch: Array<unknown> } }) => {
    if (!action.payload || action.payload.recentSearch instanceof RecentSearch !== true) {
      logger?.warn(
        {
          payload: JSON.stringify(action),
        },
        'data model incorrect or not passed into UPDATE_RECENT_SEARCHES',
      );
    }
    const searches = action.payload.recentSearch;

    return assign({}, state, {
      search: assign({}, state.search, {
        recent: assign({}, searches),
      }),
    });
  },
  [constants.USER_LOAD_SUBSCRIPTIONS]: (
    state: UserState,
    action: { payload: { stopListings: boolean; stopOther: boolean } },
  ) => {
    if (!action.payload) {
      logger?.warn('data not passed into USER_LOAD_SUBSCRIPTIONS');

      return state;
    }

    const { stopListings, stopOther } = action.payload;

    return assign({}, state, {
      subscriptions: {
        stopListings,
        stopOther,
      },
    });
  },
  [constants.UPDATE_CTA_BUTTON_CONTEXT]: (state: UserState, action: { payload: string }) => {
    // HPWEB-5993: CTA A/B test
    return assign({}, state, {
      ctaButtonContext: action.payload,
    });
  },
  [constants.SET_INQUIRY_DATA_CACHE]: (state: UserState, action: { payload: { data: InquiryDataCache } }) => {
    if (!action.payload) {
      logger?.warn('data not passed into SET_INQUIRY_DATA_CACHE');
    }
    const inquiryDataCache = action.payload.data;

    return assign({}, state, {
      inquiryDataCache: assign({}, state.inquiryDataCache, inquiryDataCache),
    });
  },
  [constants.SET_SHARE_DATA_CACHE]: (state: UserState, action: { payload: { data: ShareDataCache } }) => {
    if (!action.payload) {
      logger?.warn('data not passed into SET_SHARE_DATA_CACHE');
    }
    const shareDataCache = action.payload.data;

    return assign({}, state, {
      shareDataCache: assign({}, state.shareDataCache, shareDataCache),
    });
  },
  [constants.SET_NOTIFICATION_SETTINGS]: (state: UserState, action: { payload: Array<NotificationOption> }) => {
    const deviceGroups: Record<string, keyof NotificationPreferences> = {
      [constants.NOTIFICATION_DEVICE_EMAIL]: 'email',
      [constants.NOTIFICATION_DEVICE_MOBILE]: 'mobile',
    };
    const newSavedSearchPreferences = initNotificationTypes();
    const newRecSearchPreferences = initNotificationTypes();
    const newPropertyUpdatePreferences = initNotificationTypes();

    if (!action.payload) {
      logger?.warn('data not passed into SET_NOTIFICATION_SETTINGS');

      return state;
    }

    // ensure payload cannot contain unsupported values
    const safePayload = action.payload.filter(
      (notificationOption: NotificationOption) =>
        notificationOption.deviceGroup !== constants.NOTIFICATION_DEVICE_BROWSER,
    );

    safePayload.forEach((notificationOption) => {
      const { deviceGroup, typeGroup, optedOut } = notificationOption;

      if (typeGroup === constants.NOTIFICATION_SAVED_SEARCH) {
        newSavedSearchPreferences[deviceGroups[deviceGroup]] = !optedOut;
      } else if (typeGroup === constants.NOTIFICATION_REC_SEARCH) {
        newRecSearchPreferences[deviceGroups[deviceGroup]] = !optedOut;
      } else if (typeGroup === constants.NOTIFICATION_PROPERTY_UPDATE) {
        newPropertyUpdatePreferences[deviceGroups[deviceGroup]] = !optedOut;
      }
    });

    return assign({}, state, {
      savedSearchPreferences: newSavedSearchPreferences,
      recSearchPreferences: newRecSearchPreferences,
      propertyUpdatePreferences: newPropertyUpdatePreferences,
    });
  },
  [constants.SET_SERVER_SIDE_COOKIES]: (state: UserState, action: { payload: Record<string, unknown> }) => {
    return assign({}, state, {
      serverSideCookies: action.payload,
    });
  },
  [constants.SET_SERVER_SIDE_SET_COOKIE_ARRAY]: (state: UserState, action: { payload: Array<unknown> }) => {
    return assign({}, state, {
      serverSideSetCookiesArray: action.payload,
    });
  },
};

const user = reduxUtils.createReducer(mapActionsToReducer, initState());

export default user;
