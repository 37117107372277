// @ts-nocheck
/* eslint-enable */
import assign from 'lodash/assign';
import api from 'app/shared/utils/api';
import constants from 'app/shared/constants/ConstantsBundle';
import userUtils from 'app/shared/utils/userUtils';
import { logError } from '@zg-rentals/log-error';

const UserActions = {
  sendFeedback({ userName, userEmail, category, comment }) {
    return function (dispatch) {
      return dispatch(api.user.feedback({ userName, userEmail, category, comment }));
    };
  },
  updateShareCache(shareData) {
    return function (dispatch) {
      dispatch({
        type: constants.SET_SHARE_DATA_CACHE,
        payload: {
          data: shareData,
        },
      });
    };
  },
  inquiry: {
    updateMessageCache(inquiryData) {
      return function (dispatch) {
        const { address, defaultMessage, displayName, email, phone, name, text } = inquiryData;
        let updateText = true;

        const inquiryCache = {
          email,
          phone,
          name,
        };

        if (
          text.length < 1 ||
          text.includes(displayName) ||
          text.includes(address) ||
          text.includes(constants.CONTACT_INQUIRY_PREFIX) ||
          text.includes(constants.CONTACT_INQUIRY_SUFFIX) ||
          (defaultMessage && text.includes(defaultMessage))
        ) {
          updateText = false;
        }

        if (updateText) {
          inquiryCache.text = text;
        }

        dispatch({
          type: constants.SET_INQUIRY_DATA_CACHE,
          payload: {
            data: assign({}, inquiryCache),
          },
        });
      };
    },
    getMessageCache() {
      return function (dispatch) {
        return dispatch(api.user.inquiry.getMessageCache())
          .then((result) => {
            let inquiryData;

            if (result && result.data) {
              inquiryData = {
                data: assign({}, result.data, {
                  isPending: false,
                }),
              };
            }

            dispatch({
              type: constants.SET_INQUIRY_DATA_CACHE,
              payload: inquiryData,
            });
            return result;
          })
          .catch(() => {
            dispatch({
              type: constants.SET_INQUIRY_DATA_CACHE,
              payload: {
                data: {
                  isPending: false,
                },
              },
            });
            return false;
          });
      };
    },
    validateUserContactInfo(queryParams) {
      return (dispatch) => {
        return dispatch(api.user.validatePhoneAndEmail(queryParams)).catch((error) => {
          logError({ error, context: 'Error with validatePhoneAndEmail API', errorType: 'UserActionsError' });
          return error;
        });
      };
    },
  },
  renterProfile: {
    get() {
      return (dispatch) => {
        return dispatch(api.user.renterProfile.get()).then((result) => {
          if (result?.data) {
            const parsedRenterProfileData = userUtils.mapRenterProfileApiToRedux(result.data);

            dispatch({
              type: constants.UPDATE_RENTER_PROFILE,
              payload: parsedRenterProfileData,
            });

            return result.data;
          }
        });
      };
    },
    update(params = {}) {
      return (dispatch) => {
        return dispatch(api.user.renterProfile.update(params)).then((result) => {
          if (result?.data) {
            const parsedRenterProfileData = userUtils.mapRenterProfileApiToRedux(result.data);

            dispatch({
              type: constants.UPDATE_RENTER_PROFILE,
              payload: parsedRenterProfileData,
            });

            return result.data;
          }
        });
      };
    },
  },
  hpTours: {
    fetchScheduledToursForUser() {
      return (dispatch) => {
        return dispatch(api.hpToursV3.scheduledToursForUser())
          .then((res) => {
            if (res.success) {
              const scheduledToursForUser = res.data.scheduledTours;
              dispatch({
                type: constants.UPDATE_SCHEDULED_TOURS_FOR_USER,
                payload: scheduledToursForUser,
              });
              return scheduledToursForUser;
            } else {
              // No reason to trigger error is user has no scheduled tours
              if (res.message !== 'No tours scheduled for user') {
                logError({
                  error: 'hpTours#fetchScheduledToursForUser',
                  errorType: 'UserActionsError',
                });
              }
              dispatch({
                type: constants.UPDATE_SCHEDULED_TOURS_FOR_USER,
                payload: [],
              });
              return [];
            }
          })
          .catch((err) => {
            logError({
              error: err,
              context: 'Error in hpTours#fetchScheduledToursForUser',
              errorType: 'UserActionsError',
            });
            dispatch({
              type: constants.UPDATE_SCHEDULED_TOURS_FOR_USER,
              payload: [],
            });
            return [];
          });
      };
    },
    addScheduledTourForUser(scheduledToursForUser, newScheduledTour) {
      return (dispatch) => {
        const updatedScheduledToursForUser = [...scheduledToursForUser, newScheduledTour];
        dispatch({
          type: constants.UPDATE_SCHEDULED_TOURS_FOR_USER,
          payload: updatedScheduledToursForUser,
        });
      };
    },
  },
  updateNetPromoterScore({ email, userToken, score, comment, date }) {
    return (dispatch) => {
      return dispatch(api.user.updateNetPromoterScore({ email, userToken, score, comment, date }));
    };
  },
};

export default UserActions;
