import type { LogHttpRequest } from '../../index';
import type { JsHttpRequestRecordDto } from '@zg-rentals/ts-dragnet-client';

const knownHeaderNames = {
  accept: 'accept',
  'accept-charset': 'acceptCharset',
  'accept-encoding': 'acceptEncoding',
  'accept-language': 'acceptLanguage',
  'cache-control': 'cacheControl',
  connection: 'connection',
  'content-encoding': 'contentEncoding',
  'content-language': 'contentLanguage',
  'content-length': 'contentLength',
  'content-type': 'contentType',
  cookie: 'cookie',
  dnt: 'dnt',
  host: 'host',
  'if-modified-since': 'ifModifiedSince',
  pragma: 'pragma',
  referer: 'referer',
  'user-agent': 'userAgent',
  'x-forwarded-for': 'xForwardedFor',
  'x-requested-with': 'xRequestedWith',
};

type PrimaryHeaders = Record<keyof typeof knownHeaderNames, string>;

export function generateDragnetHttpRequest(httpRequest: LogHttpRequest): JsHttpRequestRecordDto {
  const urlObj = new URL(httpRequest.url);

  const primaryHeaders: PrimaryHeaders = {} as PrimaryHeaders;
  const otherHeaders: Record<string, string> = {};
  Object.entries(httpRequest.headers ?? {}).forEach(([key, value]) => {
    if (knownHeaderNames[key as keyof typeof knownHeaderNames]) {
      primaryHeaders[knownHeaderNames[key as keyof typeof knownHeaderNames] as keyof PrimaryHeaders] = value;
    } else {
      otherHeaders[key] = value;
    }
  });

  return {
    ...primaryHeaders,
    body: httpRequest.body,
    created: Date.now(),
    hostname: urlObj.hostname,
    httpMethod: httpRequest.method,
    ip: httpRequest.ip,
    httpParams: JSON.stringify(httpRequest.params ?? {}),
    origin: urlObj.origin,
    otherHeaders: JSON.stringify(otherHeaders),
    path: urlObj.pathname,
    port: parseInt(urlObj.port ?? '443', 10),
    protocol: urlObj.protocol,
    queryString: urlObj.search.replace('?', ''),
  };
}
