const sensitiveFields: Array<string> = [
  'username',
  'userMessage',
  'firstName',
  'lastName',
  'middleName',
  'fullName',
  'email',
  'phone',
  'address',
  'password',
  'pastEmployments',
  'creditScore',
  'yearlyIncome',
  'street',
  'city',
  'state',
  'mailto',
  'ssn',
  'dob',
  'zip',
  'gen',
  'answers',
  'province',
  'postalCode',
];

export const REDACT_FIELD = '***REDACTED***';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function redactJsonBody(payload: { [key: string | number]: any }) {
  try {
    if (typeof payload !== 'object' || payload === null) return payload;

    const isArray = Array.isArray(payload);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const redactedPayload: { [key: string | number]: any } = isArray ? [] : {};

    if (isArray) {
      for (let i = 0; i < payload.length; i++) {
        redactedPayload.push(redactJsonBody(payload[i]));
      }
    } else {
      for (const key of Object.keys(payload)) {
        if (sensitiveFields.some((field) => key.toLowerCase().includes(field.toLowerCase()))) {
          redactedPayload[key] = REDACT_FIELD;
        } else if (typeof payload[key] === 'object' && payload[key] !== null) {
          redactedPayload[key] = redactJsonBody(payload[key]);
        } else {
          redactedPayload[key] = payload[key];
        }
      }
    }

    return redactedPayload;
  } catch (e) {
    return payload;
  }
}

function redactFormEncoded(payload: string): string {
  const form = new URLSearchParams(payload);
  for (const key of form.keys()) {
    if (sensitiveFields.some((field) => key.toLowerCase().includes(field.toLowerCase()))) {
      form.set(key, REDACT_FIELD);
    }
  }
  return form.toString();
}

function isFormEncoded(s: string): boolean {
  const pattern = /^[a-zA-Z0-9_-]+=[a-zA-Z0-9_-]+(&[a-zA-Z0-9_-]+=[a-zA-Z0-9_-]+)*$/;
  return pattern.test(s);
}

export function redactUserInfo(payload: string): string {
  try {
    return JSON.stringify(redactJsonBody(JSON.parse(payload)));
  } catch (e) {
    // ignore this error
  }
  try {
    if (isFormEncoded(payload)) {
      return redactFormEncoded(payload);
    }
  } catch (e) {
    // ignore this error
  }

  return payload;
}
