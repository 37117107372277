import assign from 'lodash/assign';
import constants from 'app/shared/constants/ConstantsBundle';
import reduxUtils from 'app/shared/utils/reduxUtils';
import { findListingIndex, updateUserItemTypes } from 'app/shared/utils/listingsReducerUtils';
import type { ListingDetails } from 'app/types/listingDetails.type';

export interface FetchListingState {
  fetchListingFailed: boolean;
  isListingLoading: boolean;
  similarListings: Array<ListingDetails>;
  relatedRentals: Array<ListingDetails>;
}

const initState = (): FetchListingState => ({
  fetchListingFailed: false,
  isListingLoading: false,
  similarListings: [],
  relatedRentals: [],
});

const mapActionsToReducer = {
  [constants.RESET_FETCH_LISTING_STATE]: (
    state: FetchListingState,
    action: { payload: { listingIsLoading: boolean } },
  ) => {
    const { listingIsLoading } = action.payload;

    return assign({}, state, {
      fetchListingFailed: false,
      isListingLoading: listingIsLoading,
    });
  },
  [constants.FETCH_LISTING_FAIL]: (state: FetchListingState, action: { payload: { error: boolean } }) => {
    const { error } = action.payload;

    return assign({}, state, {
      fetchListingFailed: error,
      isListingLoading: false,
    });
  },
  [constants.FETCH_LISTING_SUCCESS]: (state: FetchListingState) => {
    return assign({}, state, {
      fetchListingFailed: false,
      isListingLoading: false,
    });
  },
  [constants.FETCH_SIMILAR_LISTINGS_SUCCESS]: (state: FetchListingState, action: { payload: Array<unknown> }) => {
    const payload = action.payload;

    return assign({}, state, {
      similarListings: payload,
    });
  },
  [constants.FETCH_SIMILAR_LISTINGS_RESET]: (state: FetchListingState) => {
    return assign({}, state, {
      similarListings: [],
    });
  },
  [constants.FETCH_RELATED_RENTALS_SUCCESS]: (state: FetchListingState, action: { payload: Array<unknown> }) => {
    const payload = action.payload;

    return assign({}, state, {
      relatedRentals: payload,
    });
  },
  [constants.FETCH_RELATED_RENTALS_RESET]: (state: FetchListingState) => {
    return assign({}, state, {
      relatedRentals: [],
    });
  },
  [constants.USER_ITEM_OPTIMISTIC_TOGGLE]: (
    state: FetchListingState,
    action: { payload: { listing: ListingDetails; type: string; action: string } },
  ) => {
    const listing = action.payload.listing;
    const type = action.payload.type; // favorite, viewed, hidden, inquiry, etc.
    const addOrRemove = action.payload.action; // add, remove

    if (!listing.maloneLotIdEncoded || !listing.geo) {
      return state;
    }

    let tempListingToUpdate: ListingDetails;
    const indexInSimilarListings = findListingIndex(state.similarListings, listing);

    if (indexInSimilarListings > -1) {
      state.similarListings = [...state.similarListings];
      tempListingToUpdate = assign({}, state.similarListings[indexInSimilarListings]) as ListingDetails;
      tempListingToUpdate.userItemTypes = updateUserItemTypes(tempListingToUpdate.userItemTypes, addOrRemove, type);
      state.similarListings[indexInSimilarListings] = tempListingToUpdate;
    }

    return assign({}, state);
  },
};

const fetchListing = reduxUtils.createReducer(mapActionsToReducer, initState());

export default fetchListing;
