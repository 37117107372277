// Auto generated file, do not manually modify!
// com.hotpads.dragnet.web.js.DragnetJsBrowserCollectionHandler

import { getClient } from '../../client';
import { httpApiRequest } from '@zg-rentals/ts-client-http-client';

import type { JsExceptionRecordBatchDto, PublishingResponseDto } from '../..';

export const DragnetJsBrowserCollectionHandler_browserExceptions_Path = '/collect/browserExceptions';

export const DragnetJsBrowserCollectionHandler_browserExceptions = (
  postData: JsExceptionRecordBatchDto,
  client = getClient(),
) => {
  return httpApiRequest<never, JsExceptionRecordBatchDto, PublishingResponseDto>(
    {
      endpoint: DragnetJsBrowserCollectionHandler_browserExceptions_Path,
      method: 'POST',
      contextPath: '/dragnet',
      postData: postData,
    },
    client,
  );
};
