import type { LogEvent } from 'pino';
import type { Logger, LoggerOptions } from '@zg-rentals/logger-base';
import { createLogger, trackChildLoggers } from '@zg-rentals/logger-base';
import { ThrottledBatchWriter } from '@zg-rentals/util';
import { isbot } from 'isbot';

export default function getBrowserLogger({ logPath, ...options }: LoggerOptions = {}): Logger {
  options.level ??= 'info';

  const bw = new ThrottledBatchWriter(async (batch: Array<LogEvent>) => {
    if (logPath && typeof window !== 'undefined' && !isbot(window.navigator.userAgent)) {
      const body = JSON.stringify(batch);

      navigator.sendBeacon?.(
        logPath,
        new Blob([body], {
          type: 'application/json',
        }),
      ) ?? fetch(logPath, { body, method: 'POST', keepalive: true, headers: { 'content-type': 'application/json' } });
    }
  }, 2000);

  options.browser ??= {};
  options.browser.transmit ??= {
    send: (_, logEvent) => {
      if (logEvent.level.value >= logger.levelVal) {
        bw.write(logEvent);
      }
    },
  };

  const logger = createLogger(options);
  trackChildLoggers(logger);

  return logger;
}
