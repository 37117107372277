import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function getPis() {
  return cookies.get('pis');
}

export function incrementPis() {
  let pis = cookies.get('pis') || 0;
  pis = Number(pis);
  pis += 1;
  cookies.set('pis', pis);
}

export function clearPis() {
  cookies.remove('pis');
}

export function getGuidCookie() {
  return cookies.get('guid');
}
